import React from 'react';
import { images } from '../../../assets/index';
import './header.scss';

export const Header = ({className, title, subtitle, setRef}) => {
  return (
    <div id={'header'} className={`${className ? className : ''}`} ref={setRef}>
      <div className={'wrapper'}>
        <img className={'logo'} src={images.logo} alt="@null"/>
        <div className={'title'}>{title}</div>
      </div>
      <div className={'subtitle'}>{subtitle}</div>
    </div>
  );
};

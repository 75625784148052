import {
  Home,
  MentorForm,
  MentorHome,
  SchoolHome,
  SchoolForm,
  LegalMentions
} from '../ui/screens';
import { Constant } from '../utils/Constant';

export default {
  home: {
    path: Constant.PATH.HOME,
    component: Home,
  },
  homeMentor: {
    path: Constant.PATH.MENTOR,
    component: MentorHome,
  },
  mentorForm: {
    path: Constant.PATH.MENTOR_INSCRIPTION,
    component: MentorForm,
  },
  homeSchool: {
    path: Constant.PATH.SCHOOL,
    component: SchoolHome,
  },
  schoolForm: {
    path: Constant.PATH.SCHOOL_INSCRIPTION,
    component: SchoolForm,
    authRecord: true,
  },
  legalMentions: {
    path: Constant.PATH.LEGAL_MENTIONS,
    component: LegalMentions
  },
};

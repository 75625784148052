import { call, put, select, takeLatest } from 'redux-saga/effects';
import { history } from '../../core/history';
import { LoaderActions } from '../loader';
import { SnackActions } from '../snackBar';
import { InscriptionServices } from '../../services';
import { Constant } from '../../utils';
import { types } from './actions';
import { InscriptionsActions } from './index';
import errorService from "../../services/Error/ErrorService";
import { StatusCodes } from "http-status-codes";

function* createMentorRequested({mentor}) {
  yield put(LoaderActions.loading());
  const [error] = yield call(InscriptionServices.createMentor, mentor);

  if (error) {
    yield errorService.displayConflictError(error, 'error_mentor_creation')
  } else {
    yield call(history.replace, Constant.PATH.MENTOR);
    yield put(SnackActions.displayInfo('success_mentor_creation'));
  }

  yield put(LoaderActions.loaded());
}

function* createSchoolInscriptionRequested({firstName, lastName, email}) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(InscriptionServices.createSchoolInscription, firstName, lastName, email);

  if (error) {
    if (error.response.status === StatusCodes.CONFLICT) {
      yield put(SnackActions.displayError('error_school_inscription_already_exist'));
    } else {
      yield put(SnackActions.displayError('error_school_inscription_creation'));
    }
  } else {
    yield put(InscriptionsActions.resetSchoolInscription());
    yield put(InscriptionsActions.createSchoolInscriptionSucceeded(response.data));
    yield call(getSchoolInscriptionRequested, {id: response.data.id});
  }

  yield put(LoaderActions.loaded());
}

function* loginSchoolRequested({code, password}) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(InscriptionServices.loginSchool, code, password);

  if (error) {
    yield put(SnackActions.displayError('error_school_login'));
  } else {
    yield put(InscriptionsActions.resetSchoolInscription());
    yield put(InscriptionsActions.loginSchoolSucceeded(response.data));
    yield call(getSchoolInscriptionRequested, {id: response.data.id});
  }

  yield put(LoaderActions.loaded());
}

function* getSchoolInscriptionRequested({id}) {
  const [error, response] = yield call(InscriptionServices.getSchoolInscription, id);

  if (error) {
    yield put(SnackActions.displayError('error_school_retrieval'));
  } else {
    yield put(InscriptionsActions.getSchoolSucceeded(response.data));
    yield call(history.replace, Constant.PATH.SCHOOL_INSCRIPTION);
  }
}

function* getRneRequested({rne}) {
  yield put(LoaderActions.loading());
  const [error, response] = yield call(InscriptionServices.getRne, rne);

  if (error) {
    if (error.response.status === StatusCodes.NOT_FOUND) {
      yield put(InscriptionsActions.getRneFailed(rne))
      yield put(InscriptionsActions.alreadyExist(false))
    }
  } else {
    yield put(InscriptionsActions.getRneSucceeded(response.data))
    yield put(InscriptionsActions.alreadyExist(true))
    yield put(SnackActions.displayInfo('rne_exists'))

  }

  yield put(LoaderActions.loaded());
}

function* updateSchoolRequested({school}) {
  yield put(LoaderActions.loading());

  const {record} = yield select(state => state.inscriptions);
  //If a rne has been search, add the rne code to the school props
  const {rne} = yield select(state => state.inscriptions);
  const schoolProps = {...school};
  if(rne) {
    schoolProps.rne = rne.rne
  }
  const [error, response] = yield call(InscriptionServices.updateSchool, record.id, {id: parseInt(record.id, 10), school: {...schoolProps}});

  if (error) {
    if (error.response.status === StatusCodes.CONFLICT) {
      if (error.response.code === Constant.CONFLICT_ERROR_CODES.RNE_CONFLICT) {
        yield put(SnackActions.displayError('error_email_exists'));
      } else {
        yield put(SnackActions.displayError('error_rne_exists'));
        yield put(InscriptionsActions.setConflictEmails(error.response.data.detailedMessages));
      }
    } else {
      yield put(SnackActions.displayError('error_school_update'));
    }
    yield put(InscriptionsActions.updateSchoolFailed());
  } else {
    yield put(InscriptionsActions.updateSchoolSucceeded(response.data));
  }

  yield put(LoaderActions.loaded());
}

function* validateSchoolRequested({school}) {
  yield put(LoaderActions.loading())
  const {record, rne, alreadyExist} = yield select(state => state.inscriptions)
  yield put(InscriptionsActions.saveSchoolBeforeSubmission(school))

  const [saveError, response] = yield call(InscriptionServices.updateSchool, record.id, {id: parseInt(record.id, 10), school: {...school}, fk_schoolId: rne.id, alreadyExist: alreadyExist})
  if (saveError) {
    if (saveError.response.status === StatusCodes.CONFLICT) {
      yield put(SnackActions.displayError('error_email_exists'))
      yield put(InscriptionsActions.setConflictEmails(saveError.response.data.detailedMessages))
    } else {
      yield put(SnackActions.displayError('error_school_update'))
    }
  } else {
    yield put(InscriptionsActions.updateSchoolSucceeded(response.data))
    let responseValid = true
    let responses
    if (response.data.school.status === Constant.SCHOOL_STATUS.DRAFT) {
      responses = yield call(InscriptionServices.validateSchool, record.id, Constant.SCHOOL_STATUS.PENDING)
    }

    if(responses) {
      responses.forEach(response => {
        if (response && parseInt(response.status) !== StatusCodes.OK) {
          responseValid = false
        }
      })
    }

    if (responses && !responseValid) {
      yield put(SnackActions.displayError('error_school_validation'))
    } else {
      yield call(history.replace, Constant.PATH.SCHOOL)
      yield put(InscriptionsActions.validateSchoolSucceeded())
      if (alreadyExist) {
        yield put(SnackActions.displayInfo('success_school_update'))
      } else {
        yield put(SnackActions.displayInfo('success_school_creation'))
      }
    }
  }

  yield put(LoaderActions.loaded())
}

export default [
  takeLatest(types.CREATE_MENTOR_REQUESTED, createMentorRequested),
  takeLatest(types.CREATE_SCHOOL_INSCRIPTION_REQUESTED, createSchoolInscriptionRequested),
  takeLatest(types.LOGIN_SCHOOL_REQUESTED, loginSchoolRequested),
  takeLatest(types.GET_RNE_REQUESTED, getRneRequested),
  takeLatest(types.UPDATE_SCHOOL_REQUESTED, updateSchoolRequested),
  takeLatest(types.VALIDATE_SCHOOL_REQUESTED, validateSchoolRequested),
];

import React, { PureComponent } from 'react';
import {icons } from '../../../../assets';
import './wrapper.scss';

export default class Wrapper extends PureComponent {

  render() {
    const {id, wrapperClass, cancelAction, children, displayed, closable = true} = this.props;
    return (
      <div id={id} className={`popup ${displayed ? 'displayed' : ''}`}>
        <div className={`wrapper ${wrapperClass ? wrapperClass : ''}`}>
          {closable && <i className={'material-icons'} onClick={cancelAction}>{icons.CLOSE}</i>}
          {children}
        </div>
      </div>
    );
  }
}

import {Constant} from '../../../../utils';

export const validateFullSchoolForm = (schoolInfo, principalInfo) => {

  const validateProperty = (field, minLength) => {
    const onError = !field.value || field.value.length < minLength;
    return { onError };
  };
  const validateField = (field, minLength, errorMessage) => {
    const validation = validateProperty(field, minLength);
    field.onError = validation.onError;
    field.errorMsg = errorMessage;
  }

  const validatePropertyRegex = (field, regex) => {
    const onError = !field.value || !regex.test(field.value);
    return { onError };
  };

  const validateFieldRegex = (field, minLength, regex, errorMessage, requiredErrorMessage) => {
    const validation = validatePropertyRegex(field, regex);
    field.onError = validation.onError;
    field.errorMsg = validation.onError ? errorMessage : requiredErrorMessage;
  };

  const schoolInfoCopy = JSON.parse(JSON.stringify(schoolInfo));
  // Validate school info
  const {name, address, postalCode, city, region, academy, country, secretariatPhone, rne} = schoolInfoCopy;

  validateField(name, 2, 'input_require');
  validateField(address, 2, 'input_require');
  validateField(postalCode, 2, 'input_require');
  validateField(city, 2, 'input_require');
  validateField(region, 2, 'input_require');
  validateField(academy, 2, 'input_require');
  validateField(country, 2, 'input_require');
  validateFieldRegex(secretariatPhone, null, Constant.REGEX.PHONE, 'wrong_phone', 'input_require');
  validateFieldRegex(rne, null, Constant.REGEX.RNE, 'input_rne_require', 'input_require');

  const principalInfoCopy = JSON.parse(JSON.stringify(principalInfo));

  // Validate principal info
  const {civility, lastName, firstName, phone, email} = principalInfoCopy;

  validateField(civility, 2, 'input_require');
  validateField(lastName, 2, 'input_require');
  validateField(firstName, 2, 'input_require');
  validateFieldRegex(email, null, Constant.REGEX.EMAIL, 'wrong_email', 'input_require');
  validateFieldRegex(phone, null, Constant.REGEX.PHONE, 'wrong_phone', 'input_require');

  return {schoolInfo: schoolInfoCopy, principalInfo: principalInfoCopy};
};

export const validatePartialSchoolForm = (schoolInfo, principalInfo) => {
  const schoolInfoCopy = JSON.parse(JSON.stringify(schoolInfo));
  // Validate school info
  const {name, address, postalCode, city, region, academy, country, secretariatPhone, rne} = schoolInfoCopy;

  name.onError = name.value && name.value.length < 2;
  name.errorMsg = 'input_invalid';
  address.onError = address.value && address.value.length < 2;
  address.errorMsg = 'input_invalid';
  postalCode.onError = postalCode.value && postalCode.value.length < 2;
  postalCode.errorMsg = 'input_invalid';
  city.onError = city.value && city.value.length < 2;
  city.errorMsg = 'input_invalid';
  region.onError = region.value && region.value.length < 2;
  region.errorMsg = 'input_invalid';
  academy.onError = academy.value && academy.value.length < 2;
  academy.errorMsg = 'input_invalid';
  country.onError = country.value && country.value.length < 2;
  country.errorMsg = 'input_invalid';
  secretariatPhone.onError = secretariatPhone.value && !Constant.REGEX.PHONE.test(secretariatPhone.value);
  secretariatPhone.errorMsg = 'wrong_phone';
  rne.onError = !rne.value || !/^[\d]{7}[a-zA-Z]$/.test(rne.value);
  rne.errorMsg = 'input_rne_require';

  const principalInfoCopy = JSON.parse(JSON.stringify(principalInfo));
  // Validate principal info
  const {lastName, firstName, phone, email} = principalInfoCopy;
  lastName.onError = lastName.value && lastName.value.length < 2;
  lastName.errorMsg = 'input_invalid';
  firstName.onError = firstName.value && firstName.value.length < 2;
  firstName.errorMsg = 'input_invalid';
  email.onError = email.value && email.value.length > 1 && !Constant.REGEX.EMAIL.test(email.value);
  email.errorMsg = 'wrong_email';
  phone.onError = phone.value && phone.value.length > 1 && !Constant.REGEX.PHONE.test(phone.value);
  phone.errorMsg = 'wrong_phone';

  return {schoolInfo: schoolInfoCopy, principalInfo: principalInfoCopy};
};

export const validatePartialProjectsForm = (projects) => {
  const projectsCopy = JSON.parse(JSON.stringify(projects));

  return validateProjectsForm(projectsCopy);
};

export const validateFullProjectsForm = (projects) => {
  const projectsCopy = JSON.parse(JSON.stringify(projects));

  projectsCopy.forEach(project => {
    project.onError = project.value.supervisors.length === 0;
  });

  return validateProjectsForm(projectsCopy);
};

const validateProjectsForm = (projects) => {
  const supervisorsMap = {};

  projects.forEach(project => {
    project.value.type.onError = !project.value.type.value;
    project.value.season.onError = !project.value.season.value;
    const projectSupervisors = [];
    project.value.supervisors.forEach(supervisor => {
      const result = validateSupervisorForm(supervisor);

      //Prepare a map of email/supervisors
      if (!result.email.onError) {
        if (projectSupervisors.includes(supervisor.email.value)) {
          supervisor.email.onError = true;
          supervisor.email.errorMsg = 'duplicate_email';
        } else if (supervisorsMap[supervisor.email.value.toLowerCase()]) {
          supervisorsMap[supervisor.email.value.toLowerCase()].push(supervisor);
        } else {
          supervisorsMap[supervisor.email.value.toLowerCase()] = [supervisor];
        }
      }
      projectSupervisors.push(supervisor.email.value);
    })
    return null;
  });

  //Verify supervisors consistency
  Object.values(supervisorsMap).map((supervisors) => {
    for(let i = 0; i < supervisors.length - 1; i++) {
      for(let j = 1; j < supervisors.length; j++) {
        const civilityMismatch = !supervisors[i].civility.value || !supervisors[j].civility.value || supervisors[i].civility.value.toLowerCase() !== supervisors[j].civility.value.toLowerCase();
        if (civilityMismatch) {
          supervisors[i].civility.onError = civilityMismatch;
          supervisors[j].civility.onError = civilityMismatch;
          supervisors[i].civility.errorMsg = 'inconsistent_supervisor';
          supervisors[j].civility.errorMsg = 'inconsistent_supervisor';
        }

        const firstNameMismatch = !supervisors[i].firstName.value || !supervisors[j].firstName.value || supervisors[i].firstName.value.toLowerCase() !== supervisors[j].firstName.value.toLowerCase();
        if (firstNameMismatch) {
          supervisors[i].firstName.onError = firstNameMismatch;
          supervisors[j].firstName.onError = firstNameMismatch;
          supervisors[i].firstName.errorMsg = 'inconsistent_supervisor';
          supervisors[j].firstName.errorMsg = 'inconsistent_supervisor';
        }

        const lastNameMismatch = !supervisors[i].lastName.value || !supervisors[j].lastName.value || supervisors[i].lastName.value.toLowerCase() !== supervisors[j].lastName.value.toLowerCase();
        if (lastNameMismatch) {
          supervisors[i].lastName.onError = lastNameMismatch;
          supervisors[j].lastName.onError = lastNameMismatch;
          supervisors[i].lastName.errorMsg = 'inconsistent_supervisor';
          supervisors[j].lastName.errorMsg = 'inconsistent_supervisor';
        }

        const emailMismatch = !supervisors[i].email.value || !supervisors[j].email.value || supervisors[i].email.value.toLowerCase() !== supervisors[j].email.value.toLowerCase();
        if (emailMismatch) {
          supervisors[i].email.onError = emailMismatch;
          supervisors[j].email.onError = emailMismatch;
          supervisors[i].email.errorMsg = 'inconsistent_supervisor';
          supervisors[j].email.errorMsg = 'inconsistent_supervisor';
        }

        const phoneMismatch = !supervisors[i].phone.value || !supervisors[j].phone.value || supervisors[i].phone.value.toLowerCase() !== supervisors[j].phone.value.toLowerCase();
        if (phoneMismatch) {
          supervisors[i].phone.onError = phoneMismatch;
          supervisors[j].phone.onError = phoneMismatch;
          supervisors[i].phone.errorMsg = 'inconsistent_supervisor';
          supervisors[j].phone.errorMsg = 'inconsistent_supervisor';
        }
      }
    }
    return null;
  });

  return projects;
};

export const validateSupervisorForm = (supervisor) => {
  const {civility, lastName, firstName, phone, email} = supervisor;
  civility.onError = !civility.value;
  civility.errorMsg = 'input_require';
  lastName.onError = !lastName.value || lastName.value.length < 2;
  lastName.errorMsg = 'input_require';
  firstName.onError = !firstName.value || firstName.value.length < 2;
  firstName.errorMsg = 'input_require';
  email.onError = !email.value || !Constant.REGEX.EMAIL.test(email.value);
  email.errorMsg = !email.value ? 'input_require' : 'wrong_email';
  phone.onError = !phone.value || !Constant.REGEX.PHONE.test(phone.value);
  phone.errorMsg = !phone.value ? 'input_require' : 'wrong_phone';

  return supervisor;
};

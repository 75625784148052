import React, { PureComponent } from 'react';
import { images } from '../../../../assets';
import { Constant, usePageViews } from '../../../../utils';
import { Button, ButtonColors } from '../../../components';
import './home.scss';

export class Home extends PureComponent {
  render() {
    const { strings, navigateTo } = this.props;
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
    return (
      <div id={'homeMentor'}>
        <img
          className={'background'}
          src={images.background}
          alt={'background'}
        />
        <div className={'container'}>
          <div className={'header'}>
            <h1 className={'title'}>{strings('mentor_home_title')}</h1>
            <div className="text">
              <div className="desc">
                <p className={'intro'}>{strings('mentor_home_desc')}</p>
                <p className={'desc'}>{strings('mentor_home_subdesc')}</p>
              </div>
              <img src={images.sunavatar} alt={'avatar'} />
            </div>
          </div>
          <div className={'inscription'}>
            <div className={'info'}>
              <p className={'label'}>{strings('inscription_title')}</p>
              <p className={'desc'}>
                {strings('mentor_home_inscription_desc')}
              </p>
            </div>
            <div className={'action-wrapper'}>
              <Button
                color={ButtonColors.blue}
                label={strings('register')}
                className={'register'}
                action={() => navigateTo(Constant.PATH.MENTOR_INSCRIPTION)}
              />
            </div>
          </div>
          <div className={'information'}>
            <div className={'single-block'}>
              <p className={'label'}>
                {strings('mentor_home_info_bloc1_title')}
              </p>
              <p className={'desc'}>{strings('mentor_home_info_bloc1_desc')}</p>
            </div>
            <div className={'double-block'}>
              <p className={'label'}>
                {strings('mentor_home_info_bloc2_title')}
              </p>
              <div className={'wrapper'}>
                <p className={'desc'}>
                  {strings('mentor_home_info_bloc2_desc1')}
                </p>
                <p className={'desc'}>
                  {strings('mentor_home_info_bloc2_desc2')}
                </p>
              </div>
            </div>
            <div className={'single-block'}>
              <p className={'label'}>
                {strings('mentor_home_info_bloc3_title')}
              </p>
              <p className={'desc'}>{strings('mentor_home_info_bloc3_desc')}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import {actionCreator} from '../../utils/index';

// Types
export const types = {
  CREATE_MENTOR_REQUESTED: 'CREATE_MENTOR_REQUESTED',
  CREATE_SCHOOL_INSCRIPTION_REQUESTED: 'CREATE_SCHOOL_INSCRIPTION_REQUESTED',
  CREATE_SCHOOL_INSCRIPTION_SUCCEEDED: 'CREATE_SCHOOL_INSCRIPTION_SUCCEEDED',
  LOGIN_SCHOOL_REQUESTED: 'LOGIN_SCHOOL_REQUESTED',
  LOGIN_SCHOOL_SUCCEEDED: 'LOGIN_SCHOOL_SUCCEEDED',
  GET_SCHOOL_SUCCEEDED: 'GET_SCHOOL_SUCCEEDED',
  SAVE_SCHOOL_BEFORE_SUBMISSION: 'SAVE_SCHOOL_BEFORE_SUBMISSION',
  GET_RNE_REQUESTED: 'GET_RNE_REQUESTED',
  GET_RNE_SUCCEEDED: 'GET_RNE_SUCCEEDED',
  GET_RNE_FAILED: 'GET_RNE_FAILED',
  UPDATE_SCHOOL_REQUESTED: 'UPDATE_SCHOOL_REQUESTED',
  UPDATE_SCHOOL_SUCCEEDED: 'UPDATE_SCHOOL_SUCCEEDED',
  UPDATE_SCHOOL_FAILED: 'UPDATE_SCHOOL_FAILED',
  SET_CONFLICT_EMAILS: 'SET_CONFLICT_EMAILS',
  VALIDATE_SCHOOL_REQUESTED: 'VALIDATE_SCHOOL_REQUESTED',
  VALIDATE_SCHOOL_SUCCEEDED: 'VALIDATE_SCHOOL_SUCCEEDED',
  RESET_SCHOOL_INSCRIPTION: 'RESET_SCHOOL_INSCRIPTION',
  UPDATE_ALREADY_EXIST: 'UPDATE_ALREADY_EXIST'
};

// Actions
export default {
  createMentorRequested: (mentor) => actionCreator(types.CREATE_MENTOR_REQUESTED, {mentor}),
  createSchoolInscriptionRequested: (firstName, lastName, email) => actionCreator(types.CREATE_SCHOOL_INSCRIPTION_REQUESTED, {firstName, lastName, email}),
  createSchoolInscriptionSucceeded: (record) => actionCreator(types.CREATE_SCHOOL_INSCRIPTION_SUCCEEDED, {record}),
  loginSchoolRequested: (code, password) => actionCreator(types.LOGIN_SCHOOL_REQUESTED, {code, password}),
  loginSchoolSucceeded: (record) => actionCreator(types.LOGIN_SCHOOL_SUCCEEDED, {record}),
  getSchoolSucceeded: (record) => actionCreator(types.GET_SCHOOL_SUCCEEDED, {record}),
  saveSchoolBeforeSubmission: (school) => actionCreator(types.SAVE_SCHOOL_BEFORE_SUBMISSION, {school}),
  getRneRequested: (rne) => actionCreator(types.GET_RNE_REQUESTED, {rne}),
  getRneSucceeded: (school) => actionCreator(types.GET_RNE_SUCCEEDED, {school}),
  getRneFailed: (rne) => actionCreator(types.GET_RNE_FAILED, {rne}),
  updateSchoolRequested: (school) => actionCreator(types.UPDATE_SCHOOL_REQUESTED, {school}),
  updateSchoolSucceeded: (updatedRecord) => actionCreator(types.UPDATE_SCHOOL_SUCCEEDED, {updatedRecord}),
  updateSchoolFailed: () => actionCreator(types.UPDATE_SCHOOL_FAILED),
  setConflictEmails: (emails) => actionCreator(types.SET_CONFLICT_EMAILS, {emails}),
  validateSchoolRequested: (school) => actionCreator(types.VALIDATE_SCHOOL_REQUESTED, {school}),
  validateSchoolSucceeded: () => actionCreator(types.VALIDATE_SCHOOL_SUCCEEDED),
  resetSchoolInscription: () => actionCreator(types.RESET_SCHOOL_INSCRIPTION),
  alreadyExist: (alreadyExist) => actionCreator(types.UPDATE_ALREADY_EXIST, {alreadyExist})
};

import { UNAUTHORIZED } from 'http-status-codes';
import {call, select} from 'redux-saga/effects';
import axios from 'axios';

const fetch = ({
                 method,
                 url,
                 data = {},
                 params = {},
                 headers = {},
                 responseType,
                 uploadCallBack = (pe) => {
                 },
               }) => {

  let instance = axios.create();
  instance.defaults.headers.common = {};
  const {cancelToken} = params;
  delete params.cancelToken;

  return instance({
    baseURL: process.env.REACT_APP_BASE_API_URL,
    method: method,
    url: url,
    data: data,
    params: params,
    cancelToken: cancelToken,
    responseType,
    headers: {...headers, 'X-API-KEY': process.env.REACT_APP_API_KEY, 'accept': 'application/json', 'Content-Type': 'application/json'},
    onUploadProgress: progressEvent => uploadCallBack(progressEvent),
  })
    .then(response => {
      return Promise.all([null, response]);
    })
    .catch(error => {
      // Fix proposed by TNU when error.response = undefined (for NetworkError)
      if (error.response) {
        return Promise.resolve([error]);
      }
      return Promise.resolve([{response: {status: 500}}]);
    });
};

function* recordAuthorize(service) {
  const {token} = yield select(state => state.inscriptions.auth);
  service.headers = {...service.headers, Authorization: `Bearer ${token}`};
  let [error, response] = yield call(fetch, service);

  if (error && error.response.status === UNAUTHORIZED) {
    return [error];
  }

  return [error, response];
}

export function* recordService(
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType = '' ,
  uploadCallBack = () => {}
) {
  return yield call(recordAuthorize, {
    method,
    url,
    data,
    params,
    headers,
    responseType,
    uploadCallBack
  });
}

export function* service(
  method,
  url,
  data = {},
  params = {},
  headers = {},
  responseType = ''
) {

  return yield call(fetch, {
    method,
    url,
    data,
    params,
    headers,
    responseType
  });
}

import background from './background.svg';
import close_research from './close_research.png';
import logoMiniMWhite from './logo-mini-M_white.png';
import logoMiniMGreen from './logo-mini-M_green.png';
import logoMiniS from './logo-mini-S.png';
import logo from './logo.svg';
import logoText from './logo-text.png';
import innovation from './innovation.png';
import platform from './img-plateforme.svg';
import logoMiniM from './logo-epa-mini-M.png';
import logoMiniL from './logo-epa-mini-L.png';
import imgEnseignant from './img-enseignant.png';
import idea from './idea.png';
import hands from './hands.png';
import sunavatar from './sunavatar.png';
import apprenants from './apprenants.png';
import commercial from './commercial.svg';
import equal from './equal.svg';
import mentions from './mentions.svg';
import epaLogo from './logo-epa.svg';
import epaBackground from './figure-white.svg';
import logoMiniXL from './Logo_MEXL_RVB_Orange.svg';

export default {
  close_research,
  background,
  logoMiniS,
  logoMiniMWhite,
  logoMiniMGreen,
  logoText,
  logo,
  innovation,
  platform,
  logoMiniM,
  logoMiniL,
  logoMiniXL,
  imgEnseignant,
  idea,
  hands,
  sunavatar,
  apprenants,
  commercial,
  equal,
  mentions,
  epaLogo,
  epaBackground,
};

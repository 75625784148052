import React, { PureComponent } from 'react';
import './radioButtons.scss';

export class RadioButtons extends PureComponent {

  state = {
    buttonChecked: this.props.defaultValue,
  };

  handleCheckChange = (value) => {
    const {tag, handleValueChanged} = this.props;
    this.setState({buttonChecked: value});
    handleValueChanged(tag, value);
  };

  render() {
    const {strings, buttons, disabled} = this.props;
    const {buttonChecked} = this.state;

    return (
      <div className={`radio-list ${disabled ? 'disable' : ''}`}>
        {buttons.map((button) =>
          <div className={'radio-wrapper'} onClick={!disabled ? () => this.handleCheckChange(button.value) : null}>
            <label
              className={`radio-button ${button.value === buttonChecked ? 'checked' : ''}`}
              title={'label'}>{button.label ? strings(button.label) : ' '}
              <span/>
              <img
                src={button.image}
                alt={'button'}
              />
            </label>

          </div>,
        )}
      </div>
    );
  }
}

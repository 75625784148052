import React, { Fragment } from 'react';
import { Enum } from '../../../../../../utils';

export const Supervisor = ({strings, supervisor}) => {
  const civility = supervisor.civility.value === Enum.CIVILITY.MR.key ? strings(Enum.CIVILITY.MR.label) : strings(Enum.CIVILITY.MRS.label);
  return (
    <Fragment>
      <hr/>
      <div className={'supervisor'}>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{strings('supervisor')}</p>
          </div>
          <div className={'info-value'}>
            <p>{`${civility} ${supervisor.firstName.value} ${supervisor.lastName.value}`}</p>
          </div>
        </div>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{strings('email')}</p>
          </div>
          <div className={'info-value'}>
            <p>{supervisor.email.value}</p>
          </div>

        </div>
        <div className={'info-container'}>
          <div className={'info-label'}>
            <p>{strings('phone')}</p>
          </div>
          <div className={'info-value'}>
            <p>{supervisor.phone.value}</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

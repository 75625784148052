import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { InscriptionsActions } from '../../../../redux/inscriptions';
import { Home } from './Home';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
  login: (code, password) => dispatch(InscriptionsActions.loginSchoolRequested(code, password)),
  createInscription: (firstName, lastName, email) => dispatch(InscriptionsActions.createSchoolInscriptionRequested(firstName, lastName, email))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

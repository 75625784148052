import * as Sentry from '@sentry/browser';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { version } from '../package.json';
import { Router, store } from './core';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { Footer, Loader, Navigation, SnackBar } from './ui/components';
import { MaintenanceMode } from './ui/screens';
import { getMaintenance, loadState, saveState } from './utils';

if (loadState('epa_inscription_last-version') !== version) {
  saveState('epa_inscription_last-version', version);
  saveState('epa_inscription_state', {});
  if (caches) {
    caches.keys().then(keys => {
      for (let key of keys) caches.delete(key);
    });
  }
  window.location.reload();
}

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://4eafe2f6be8b49cbb93bcb4ced89dbc8@sentry.io/1858168',
  });
}

ReactDOM.render(
  getMaintenance() ? (
    <MaintenanceMode />
  ) : (
    <Provider store={store}>
      <Fragment>
        <Navigation />
        <Router />
        <SnackBar />
        <Loader />
        <Footer />
      </Fragment>
    </Provider>
  ),
  document.getElementById('root')
);

registerServiceWorker();

import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Navigation } from './Navigation';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);

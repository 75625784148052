import React from 'react';
import { UserForm } from './UserForm';
import './userInfo.scss';

export const UserInfo = ({strings, className, title, userInfo, handleValueChanged, disabled = false}) => {
  return (
    <div className={`user-info section ${className ? className : ''}`}>
      {title && <p className={'title'}>{title}</p>}
      <UserForm
        strings={strings}
        userInfo={userInfo}
        handleValueChanged={handleValueChanged}
        disabled={disabled}/>
    </div>
  );
};

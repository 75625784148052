import {createReducer} from 'reduxsauce';
import {types} from './actions';

const initialState = {
  auth: null,
  record: null,
  rne: null,
  updateInError: false,
  conflictingEmails: [],
  alreadyExist: false
};

const createSchoolInscriptionSucceeded = (state = initialState, action) => {
  const {record} = action;
  return {...state, auth: record};
};

const loginSchoolSucceeded = (state = initialState, action) => {
  const {record} = action;
  return {...state, auth: record};
};

const getSchoolSucceeded = (state = initialState, action) => {
  const {record} = action;
  return {...state, record: record, conflictingEmails: initialState.conflictingEmails};
};

const saveSchoolUpdateBeforeSubmission = (state = initialState, action) => {
  const {record} = state;
  const {school} = action;
  return {...state, record: {...record, school: school}, rne: {...state.rne, ...school}};
}

const getRneSucceeded = (state = initialState, action) => {
  const {record} = state;
  const {school} = action;
  return {...state, record: {...record, school: null}, rne: school};
};


const getRneFailed = (state = initialState, action) => {
  const {record} = state;
  return {...state, record: {...record, school: null}, rne: {rne: action.rne}};
}

const updateSchoolSucceeded = (state = initialState, action) => {
  const {updatedRecord} = action;
  return {...state, record: updatedRecord, updateInError: false, conflictingEmails: initialState.conflictingEmails};
};

const updateSchoolFailed = (state = initialState) => {
  return {...state, updateInError: true};
};

const setConflictEmails = (state = initialState, action) => {
  const {emails} = action;
  return {...state, conflictingEmails: emails};
};

const validateSchoolSucceeded = (state = initialState) => {
  return {...state, auth: null, record: null, rne: null};
};

const resetSchoolInscription = (state = initialState) => {
  return {...initialState};
};

const updateAlreadyExist = (state = initialState, action) => {
  return {...state, alreadyExist: action.alreadyExist}
}

export default createReducer(initialState, {
  [types.CREATE_SCHOOL_INSCRIPTION_SUCCEEDED]: createSchoolInscriptionSucceeded,
  [types.LOGIN_SCHOOL_SUCCEEDED]: loginSchoolSucceeded,
  [types.GET_SCHOOL_SUCCEEDED]: getSchoolSucceeded,
  [types.SAVE_SCHOOL_BEFORE_SUBMISSION]: saveSchoolUpdateBeforeSubmission,
  [types.GET_RNE_SUCCEEDED]: getRneSucceeded,
  [types.GET_RNE_FAILED]: getRneFailed,
  [types.UPDATE_SCHOOL_SUCCEEDED]: updateSchoolSucceeded,
  [types.UPDATE_SCHOOL_FAILED]: updateSchoolFailed,
  [types.SET_CONFLICT_EMAILS]: setConflictEmails,
  [types.VALIDATE_SCHOOL_SUCCEEDED]: validateSchoolSucceeded,
  [types.RESET_SCHOOL_INSCRIPTION]: resetSchoolInscription,
  [types.UPDATE_ALREADY_EXIST]: updateAlreadyExist
});

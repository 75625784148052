import { Constant } from '../../../../utils';

export const validateMentorForm = (userInfo, userPerimeter, userCompany) => {
  // Validate user info
  const {civility, firstName, lastName, email, phone} = userInfo;
  civility.onError = !civility.value;
  civility.errorMsg = 'input_require';
  firstName.onError = !firstName.value || firstName.value.length < 2;
  firstName.errorMsg = 'input_require';
  lastName.onError = !lastName.value || lastName.value.length < 2;
  lastName.errorMsg = 'input_require';
  email.onError = !email.value || !Constant.REGEX.EMAIL.test(email.value);
  email.errorMsg = (email.error && (!email.value || email.value.length < 1)) ? 'input_require' : 'wrong_email';
  phone.onError = !phone.value || !Constant.REGEX.PHONE.test(phone.value);
  phone.errorMsg = phone.error && phone.value && phone.value.length < 1 ? 'input_require' : 'wrong_phone';

  // Validate user perimeter
  const {description, region} = userPerimeter;
  description.onError = !description.value || description.value.length < 2;
  description.errorMsg = 'input_require';
  region.onError = !region.value;
  region.errorMsg = 'input_require';

  // Validate user company
  const {companyName, address, postalCode, city, country, ceoLastName, ceoFirstName, ceoPhone, ceoEmail} = userCompany;
  companyName.onError = !companyName.value || companyName.value.length < 2;
  companyName.errorMsg = 'input_require';
  address.onError = !address.value || address.value.length < 2;
  address.errorMsg = 'input_require';
  postalCode.onError = !postalCode.value || postalCode.value.length < 2;
  postalCode.errorMsg = 'input_require';
  city.onError = !city.value || city.value.length < 2;
  city.errorMsg = 'input_require';
  country.onError = !country.value || country.value.length < 2;
  country.errorMsg = 'input_require';
  ceoLastName.onError = ceoLastName.value && ceoLastName.value.length < 2;
  ceoLastName.errorMsg = 'input_invalid';
  ceoFirstName.onError = ceoFirstName.value && ceoFirstName.value.length < 2;
  ceoFirstName.errorMsg = 'input_invalid';
  ceoPhone.onError = ceoPhone.value && !Constant.REGEX.PHONE.test(ceoPhone.value);
  ceoPhone.errorMsg = ceoPhone.error && ceoPhone.value && ceoPhone.value.length < 1 ? 'input_require' : 'wrong_phone';
  ceoEmail.onError = ceoEmail.value && !Constant.REGEX.EMAIL.test(ceoEmail.value);
  ceoEmail.errorMsg = ceoEmail.error && ceoEmail.value && ceoEmail.value.length < 1 ? 'input_require' : 'wrong_email';

  return {userInfo, userPerimeter, userCompany};
};

import {call, put, takeLatest} from 'redux-saga/effects';
import {default as CommonsActions, types} from './actions';
import {CommonsServices} from "../../services";

function* regionsRequested() {
  const [error, response] = yield call(CommonsServices.getRegions);
  if (!error) {
    yield put(CommonsActions.regionsSucceeded(response.data));
  }
}

export default [
  takeLatest(types.REGIONS_REQUESTED, regionsRequested),
];

import React, { PureComponent } from 'react';
import {
  ButtonColors,
  Button,
  Input,
  PopupWrapper,
} from '../../../../components';
import './loginPopup.scss';

export class LoginPopup extends PureComponent {
  state = {
    id: null,
    password: null,
    idInvalid: false,
    passwordInvalid: false,
    loginTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.loginTriggered ? (isInvalid ? 'invalid' : 'valid') : '';
  };

  handleKeyPressed = e => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const { id, password } = this.state;
      this.handleLogin(id, password);
    }
  };

  handleLogin = (id, password) => {
    const { action } = this.props;
    const idInvalid = !id || id.length === 0;
    const passwordInvalid = !password || password.length < 1;

    this.setState({
      loginTriggered: true,
      idInvalid,
      passwordInvalid,
    });

    if (!idInvalid && !passwordInvalid) {
      action(id, password);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({ [tag]: value });
  };

  resetValidator = () => {
    if (this.state.loginTriggered) {
      this.setState({
        idInvalid: false,
        passwordInvalid: false,
        loginTriggered: false,
      });
    }
  };

  render() {
    const { strings, displayed, cancelAction } = this.props;
    const { id, password, idInvalid, passwordInvalid } = this.state;

    return (
      <PopupWrapper
        id={'loginPopup'}
        className={'login-form'}
        displayed={displayed}
        cancelAction={cancelAction}
      >
        <form className={'form-wrapper'} onKeyPress={this.handleKeyPressed}>
          <Input
            label={strings('code')}
            type={'text'}
            tag={'id'}
            className={'id'}
            inputClassName={`id ${this.getValidityClass(idInvalid)}`}
            error={idInvalid ? strings('input_require') : null}
            handleValueChanged={this.handleValueChanged}
          />
          <Input
            label={strings('password')}
            type={'password'}
            tag={'password'}
            className={'password'}
            inputClassName={`${this.getValidityClass(passwordInvalid)}`}
            error={passwordInvalid ? strings('input_require') : null}
            handleValueChanged={this.handleValueChanged}
          />
          <Button
            color={ButtonColors.green}
            label={strings('access')}
            className={'login'}
            action={() => this.handleLogin(id, password)}
          />
        </form>
      </PopupWrapper>
    );
  }
}

import React, { PureComponent } from 'react';
import { Constant, defaultProject, defaultSupervisor, usePageViews } from '../../../../utils';
import { FormHeader } from '../../../components';
import './form.scss';
import { formatSchool } from './Format';
import {
  validateFullSchoolForm,
  validatePartialSchoolForm,
  validatePartialProjectsForm,
  validateFullProjectsForm,
} from './FormValidator';
import { Navigation } from './navigation/Navigation';
import { WarningPopup } from './popup/WarningPopup';
import { Projects } from './projects/Projects';
import { Rne } from './rne/Rne';
import School from './schoolInfo/School';
import { Summary } from './summary/Summary';

export class Form extends PureComponent {

  SchoolFormSteps = {
    RNE: {
      value: 'RNE',
      label: 'school_inscription_step_rne',
      fullValidation: () => true,
      partialValidation: () => true,
    },
    SCHOOL: {
      value: 'SCHOOL',
      label: 'school_inscription_step_school',
      fullValidation: (saveState = true) => this._validateSchool(validateFullSchoolForm, saveState),
      partialValidation: (saveState = true) => this._validateSchool(validatePartialSchoolForm, saveState),
    },
    PROJECTS: {
      value: 'PROJECTS',
      label: 'school_inscription_step_projects',
      fullValidation: (saveState = true) => this._validateProjects(validateFullProjectsForm, saveState),
      partialValidation: (saveState = true) => this._validateProjects(validatePartialProjectsForm, saveState),
    },
    VALIDATION: {
      value: 'VALIDATION',
      label: 'school_inscription_step_validation',
      fullValidation: () => true,
      partialValidation: () => true,
    },
  };

  state = {
    giveUpWarningRaised: false,
    rneTriggered: false,
    saveTriggered: false,
    step: this.props.isSchoolFilled ? this.SchoolFormSteps.SCHOOL : this.SchoolFormSteps.RNE,
    school: this.props.school,
    supervisors: [],
    editedSupervisor: {
      project: null,
      supervisor: null,
    },
  };

  componentDidMount() {
    const {fetchRegions} = this.props;
    fetchRegions();
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.school !== this.props.school && this.isNewSchool && this.state.step === this.SchoolFormSteps.SCHOOL) {
      this.setState({
        rneTriggered: false,
        school: this.props.school
      });
    }

    if (prevState.rneTriggered && prevProps.school !== this.props.school) {
      this.setState({
        rneTriggered: false,
        step: this.SchoolFormSteps.SCHOOL,
        school: this.props.school,
      });
    }

    if (prevState.saveTriggered && prevProps.school !== this.props.school) {
      if (!this.props.updateInError) {
        this.setState({saveTriggered: false, school: this.props.school});
      }
    }
    if (prevState.saveTriggered && this.props.conflictingEmails.length > 0) {
      this.setState({saveTriggered: false, step: this.SchoolFormSteps.PROJECTS});
    }
  }

  isNewSchool = () => {
    for (const {key, value} in this.props.school.schoolInfo) {
      if (key !== 'rne' && value !== null) {
        return false
      }
    }
    return true
  }

  handleScroll = (event) => {
    if (this.navigation && this.header) {
      if (event.target.scrollingElement.scrollTop >= this.header.getBoundingClientRect().height) {
        if (!this.navigation.className.includes('sticky')) {
          this.navigation.className = `${this.navigation.className} sticky`;
          if (window.width > 960) this.formWrapper.style = `margin-top: ${this.navigation.getBoundingClientRect().height}px`;
        }
      } else {
        if (this.navigation.className.includes('sticky')) {
          const classes = this.navigation.className.split(' ');
          if (classes.indexOf('sticky') >= 0) {
            classes.splice(classes.indexOf('sticky'), 1);
            this.navigation.className = classes.join(' ');
            this.formWrapper.style = `margin-top: 0`;
          }
        }
      }
    }
  };

  updateSupervisorsList = (projects) => {
    const uniqueSupervisors = [];
    projects.map(project => project.value.supervisors)
      .reduce((acc, current) => acc.concat(current), [])
      .forEach(supervisor => {
        if (!uniqueSupervisors.find(sup => sup.email.value === supervisor.email.value)) {
          uniqueSupervisors.push(supervisor);
        }
      });

    this.setState({supervisors: uniqueSupervisors});
  };

  _validateSchool = (validation, saveState = true) => {
    const {schoolInfo, principalInfo} = this.state.school;
    const result = validation(schoolInfo, principalInfo);

    if (saveState) {
      this.setState({
        school: {
          ...this.state.school,
          schoolInfo: Object.assign({}, result.schoolInfo),
          principalInfo: Object.assign({}, result.principalInfo),
        },
      });
    }

    const fields = [];
    Object.keys(result).forEach(
      key => {
        const item = result[key];
        Object.keys(item).forEach(
          key => {
            fields.push({key: key, data: item[key]});
          },
        );
      },
    );

    return !fields.some(field => field.data.onError);
  };

  _validateProjects = (validation, saveState = true) => {
    const {school} = this.state;
    const result = validation(school.projects);

    if (saveState) {
      this.setState({
        school: {...school, projects: result},
      });
    }

    const fields = [];
    result.forEach(project => {
      fields.push({key: 'project', data: project});
      fields.push({key: 'type', data: project.value.type});
      project.value.supervisors.forEach(supervisor => {
        Object.entries(supervisor).filter(([key]) => key !== 'id')
          .forEach(
            ([key, value]) => {
              fields.push({key: key, data: value});
            },
          );
      });
    });

    const isValid = !fields.some(field => field.data.onError);

    //If the form is valid, update the supervisor list to enable future project to access to an up to date list
    if (isValid && saveState) {
      this.updateSupervisorsList(result);
    }

    return isValid;
  };

  handleInscriptionValidation = () => {
    const {validateSchool} = this.props;
    const {schoolInfo, principalInfo, projects} = this.state.school;

    this.setState({saveTriggered: true});


    validateSchool(formatSchool(schoolInfo, principalInfo, projects));
  };

  handleSave = () => {
    const {updateSchool} = this.props;
    const {step} = this.state;
    const {schoolInfo, principalInfo, projects} = this.state.school;
    if (step.partialValidation()) {
      this.setState({saveTriggered: true});
      updateSchool(formatSchool(schoolInfo, principalInfo, projects));
    }
  };

  handleGiveUp = () => {
    const {resetSchool, navigateTo} = this.props;

    this.setState({giveUpWarningRaised: false});
    resetSchool();
    navigateTo(Constant.PATH.SCHOOL);
  };

  handleRne = (rne) => {
    this.setState({rneTriggered: true});
    this.props.getRne(rne);
    // if (rne.toUpperCase() !== this.state.school.schoolInfo.rne.value) {
    //   this.setState({
    //     school: this.props.school
    //   })
    // }
  };

  handleGoToStep = (step) => {
    this.setState({step});
  };

  handleChangeStep = (translation) => {
    const {step, school} = this.state;
    //Step needs to be valid before changing
    if (
        translation === +1 &&
        ((step === this.SchoolFormSteps.SCHOOL && !step.fullValidation())
      || (step === this.SchoolFormSteps.PROJECTS && !step.fullValidation()))
    ) {
      return;
    }

    let currentIndex = null;
    Object.entries(this.SchoolFormSteps).map(([key, value], index) => {
      if (value === step) {
        currentIndex = index;
      }
      return null;
    });

    if (currentIndex !== null) {
      const newStep = Object.entries(this.SchoolFormSteps)[currentIndex + translation][1];

      //If the new step if projects and the school has no project, add a default project
      if (newStep === this.SchoolFormSteps.PROJECTS && school.projects.length < 1) {
        const project = JSON.parse(JSON.stringify(defaultProject));
        project.value.supervisors = [JSON.parse(JSON.stringify(defaultSupervisor))];

        this.setState({
          school: {...school, projects: [project]},
          editedSupervisor: {supervisor: 0, project: 0},
        });
        this.setState({step: newStep});
      } else {
        this.setState({step: newStep});
      }
    }
  };

  handleValueChanged = (mainTag, tag, value) => {
    const {school} = this.state;

    if (school[mainTag] && school[mainTag][tag]) {
      // If the region has changed reset academy
      if (mainTag === 'schoolInfo' && tag === 'region' && school.schoolInfo.region !== value) {
        school.schoolInfo.academy.value = null;
      }

      school[mainTag][tag].value = (value !== '' ? value : null);
    }

    //Reset on error state
    Object.values(school.schoolInfo).map(val => val.onError = false);
    Object.values(school.principalInfo).map(val => val.onError = false);

    this.setState({
      school: {
        ...school,
        schoolInfo: Object.assign({}, school.schoolInfo),
        principalInfo: Object.assign({}, school.principalInfo),
      },
    });
  };

  _resetProjectsError = () => {
    const {school} = this.state;
    //Double reduce to pass from array of array to simple array of elements each time (supervisor by project array to all supervisors and values by supervisor array to all values)
    school.projects.map(project => {
      project.onError = false;
      return project.value.supervisors;
    })
      .reduce((acc, current) => acc.concat(current), [])
      .map(supervisor => Object.entries(supervisor).filter(([key]) => key !== 'id').map(([, value]) => value))
      .reduce((acc, current) => acc.concat(current), [])
      .map(val => val.onError = false);

    this.setState({
      school: {...school, projects: school.projects},
    });
  };

  handleProjectValueChanged = (index, tag, value) => {
    const {school} = this.state;

    const project = school.projects[index];
    project.value[tag].value = value;

    this.setState({
      school: {...school, projects: school.projects},
    });

    this._resetProjectsError();
  };

  handleProjectSupervisorChanged = (projectIndex, supervisorIndex, supervisor) => {
    const {school} = this.state;

    const project = school.projects[projectIndex];

    project.value.supervisors[supervisorIndex] = supervisor;

    //Reset on error state
    //Double reduce to pass from array of array to simple array of elements each time (supervisor by project array to all supervisors and values by supervisor array to all values)
    school.projects.map(project => project.value.supervisors)
      .reduce((acc, current) => acc.concat(current), [])
      .map(supervisor => Object.entries(supervisor).filter(([key]) => key !== 'id').map(([, value]) => value))
      .reduce((acc, current) => acc.concat(current), [])
      .map(val => val.onError = false);

    this.setState({
      school: {...school, projects: school.projects},
    });

    this.updateSupervisorsList(school.projects);
  };

  handleAddSupervisor = (projectIndex, supervisorIndex) => {
    this._resetProjectsError();

    const {school, supervisors} = this.state;

    const project = school.projects[projectIndex];
    if (!project.value.supervisors) project.value.supervisors = [];

    if (supervisorIndex >= 0) {
      project.value.supervisors.push(JSON.parse(JSON.stringify(supervisors[supervisorIndex])));
    } else {
      project.value.supervisors.push(JSON.parse(JSON.stringify(defaultSupervisor)));
      this.setState({
        editedSupervisor: {
          supervisor: project.value.supervisors.length - 1,
          project: projectIndex,
        },
      });
    }

    this.setState({
      school: {...school, projects: school.projects},
    });
  };

  handleDeleteSupervisor = (projectIndex, supervisorIndex) => {
    this._resetProjectsError();

    const {school, editedSupervisor} = this.state;

    const project = school.projects[projectIndex];

    if (supervisorIndex >= 0) {
      project.value.supervisors.splice(supervisorIndex, 1);
    }

    this.setState({
      school: {...school, projects: JSON.parse(JSON.stringify(school.projects))},
    });

    this.updateSupervisorsList(school.projects);

    //Reset current supervisor edited
    if (editedSupervisor.supervisor === supervisorIndex && editedSupervisor.project === projectIndex) {
      this.setState({editedSupervisor: {supervisor: null, project: null}});
    }
  };

  handleAddProject = () => {
    this._resetProjectsError();

    const {school} = this.state;
    let newProjects = Object.assign([], school.projects);
    if (!newProjects) newProjects = [];

    const project = JSON.parse(JSON.stringify(defaultProject));
    if (!school.projects || school.projects.length < 1) {
      project.value.supervisors = [JSON.parse(JSON.stringify(defaultSupervisor))];
    }
    newProjects.push(project);

    this.setState({
      school: {...school, projects: newProjects},
    });

  };

  handleDeleteProject = (projectIndex) => {
    this._resetProjectsError();

    const {school, editedSupervisor} = this.state;

    const projects = school.projects;
    projects.splice(projectIndex, 1);

    this.setState({
      school: {...school, projects: JSON.parse(JSON.stringify(projects))},
    });

    this.updateSupervisorsList(school.projects);

    //Reset current supervisor edited
    if (editedSupervisor.project === projectIndex) {
      this.setState({editedSupervisor: {supervisor: null, project: null}});
    }
  };

  switchToEditMode = (projectIndex, supervisorIndex) => {
    const {editedSupervisor} = this.state;
    if (editedSupervisor.supervisor !== null && editedSupervisor.project !== null) {
      if (editedSupervisor.supervisor === supervisorIndex && editedSupervisor.project === projectIndex) {
        //Before leaving edit mode, validate projects to raise conflict error on the current cell
        if (this._validateProjects(validatePartialProjectsForm)) {
          this.setState({editedSupervisor: {supervisor: null, project: null}});
        }
      }
    } else {
      this.setState({editedSupervisor: {supervisor: supervisorIndex, project: projectIndex}});
    }
  };

  toggleWarningPopup = () => {
    const {giveUpWarningRaised} = this.state;
    //If handle give up is pressed and props and state are different raise a popup
    if (!giveUpWarningRaised && JSON.stringify(this.props.school) === JSON.stringify(this.state.school)) {
      this.handleGiveUp();
    } else {
      this.setState({giveUpWarningRaised: !giveUpWarningRaised});
    }
  };

  renderStep(strings, regions, academies, step, school, supervisors, editedSupervisor, conflictingEmails) {
    switch (step) {
      case this.SchoolFormSteps.RNE:
        return <Rne
          strings={strings}
          className={'rne-container'}
          action={this.handleRne}
        />
      case this.SchoolFormSteps.SCHOOL:
        return <School
          strings={strings}
          className={'school-container'}
          regions={regions}
          academies={school.schoolInfo.region.value ? academies(school.schoolInfo.region.value) : []}
          schoolInfo={school.schoolInfo}
          principalInfo={school.principalInfo}
          handleValueChanged={this.handleValueChanged}
          handleRneSearch={this.handleRne}
          handleNextStep={() => this.handleChangeStep(+1)}
        />
      case this.SchoolFormSteps.PROJECTS:
        return <Projects
          strings={strings}
          className={'projects-container'}
          projects={school.projects}
          supervisors={supervisors}
          editedSupervisor={editedSupervisor}
          switchToEditMode={this.switchToEditMode}
          handleValueChanged={this.handleProjectValueChanged}
          handleProjectSupervisorChanged={this.handleProjectSupervisorChanged}
          handleAddSupervisor={this.handleAddSupervisor}
          handleDeleteSupervisor={this.handleDeleteSupervisor}
          handleAddProject={this.handleAddProject}
          handleDeleteProject={this.handleDeleteProject}
          handleNextStep={() => this.handleChangeStep(+1)}
          conflictingEmails={conflictingEmails}/>;
      case this.SchoolFormSteps.VALIDATION:
        return <Summary
          strings={strings}
          className={'summary-container'}
          school={school}
          handleEditSchool={() => this.handleGoToStep(this.SchoolFormSteps.SCHOOL)}
          handleEditProjects={() => this.handleGoToStep(this.SchoolFormSteps.PROJECTS)}
          handleInscriptionValidation={this.handleInscriptionValidation}/>;
      default:
        return null;
    }
  }

  render() {
    const {strings, regions, academies, conflictingEmails} = this.props;
    const {step, supervisors, school, editedSupervisor, giveUpWarningRaised} = this.state;

    const allowPrev = step !== this.SchoolFormSteps.RNE;
    const allowNext = step !== this.SchoolFormSteps.VALIDATION && step.fullValidation(false);
      let location = window.location.pathname + window.location.search;
      usePageViews(location);
    return (
      <div id={'formSchool'}>
        <FormHeader
          title={strings('school_inscription')}
          subtitle={strings(step.label)}
          setRef={(r) => this.header = r}/>
        <div className={'container'}>
          {step !== this.SchoolFormSteps.RNE &&
          <Navigation
            strings={strings}
            handleGiveUp={this.toggleWarningPopup}
            handleSave={this.handleSave}
            handleChangeStep={this.handleChangeStep}
            allowPrevStep={allowPrev}
            allowNextStep={allowNext}
            setRef={(r) => this.navigation = r}/>
          }
          <div className={'form-wrapper'} ref={r => this.formWrapper = r}>
            {this.renderStep(strings, regions, academies, step, school, supervisors, editedSupervisor, conflictingEmails)}
          </div>
        </div>
        {giveUpWarningRaised &&
        <WarningPopup
          strings={strings}
          label={strings('give_up_replace_warning')}
          displayed={true}
          confirmAction={this.handleGiveUp}
          cancelAction={this.toggleWarningPopup}/>
        }
      </div>
    );
  }
}

import React from 'react';
import { ButtonColors, Button } from '../../../../components';
import { SchoolInfo } from '../common/SchoolInfo';
import { UserInfo } from '../../../common';
import { ProjectsSummary } from './project/ProjectsSummary';
import { usePageViews } from '../../../../../utils';

export const Summary = ({strings, className, school, handleEditSchool, handleEditProjects, handleInscriptionValidation}) => {
  const { schoolInfo, principalInfo, projects } = school;
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
  return (
    <div className={`${className ? className : ''}`}>
      <p className={'title'}>{strings('summary_title')}</p>
      <hr/>
      <SchoolInfo
        strings={strings}
        className={'school-info'}
        schoolInfo={schoolInfo}
        handleEditAction={handleEditSchool}
        disabled={true}/>
      <UserInfo
        strings={strings}
        className={'principal-info'}
        title={strings('principal')}
        userInfo={principalInfo}
        disabled={true}/>
      <hr/>
      <ProjectsSummary
        strings={strings}
        className={'projects-info'}
        projects={projects}
        handleEditAction={handleEditProjects}/>
      <Button
        color={ButtonColors.green}
        label={strings('finish_inscription')}
        className={'validate'}
        action={handleInscriptionValidation}
      />
    </div>
  );
};

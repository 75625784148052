import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { Menu } from './Menu';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(Menu);

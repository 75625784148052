export const formatSchool = (schoolInfo, principalInfo, projects) => {
  const formattedSchoolInfo = {};
  Object.entries(schoolInfo).forEach(([key, value]) => formattedSchoolInfo[key] = value.value);
  const formattedPrincipalInfo = {
    principalCivility: principalInfo.civility.value,
    principalLastName: principalInfo.firstName.value,
    principalFirstName: principalInfo.lastName.value,
    principalEmail: principalInfo.email.value,
    principalPhone: principalInfo.phone.value,
  };

  const formattedProjects = [];
    projects.forEach(project => {
      const supervisors = project.value.supervisors.map(supervisor => {
        return {
          id: supervisor.id,
          civility: supervisor.civility.value,
          lastName: supervisor.lastName.value,
          firstName: supervisor.firstName.value,
          email: supervisor.email.value,
          phone: supervisor.phone.value,
        };
      });
      formattedProjects.push(Object.assign({}, {id: project.value.id, type: project.value.type.value, season: project.value.season.value, supervisors}));
    });

    return {...formattedSchoolInfo, ...formattedPrincipalInfo, projects: formattedProjects}
};

import React, { Component } from 'react';
import './dropDown.scss';

export class DropDown extends Component {
  state = {
    displayList: false,
  };

  boxRef = React.createRef();

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.boxRef && this.boxRef.current && !this.boxRef.current.contains(event.target)) {
      this.setState({
        displayList: false,
      });
      event.stopPropagation();
    }
  };

  handleClickBox = () => {
    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });
  };

  handleClickItem = item => () => {
    const {tag, handleClickItem} = this.props;

    const {displayList} = this.state;
    this.setState({
      displayList: !displayList,
    });

    handleClickItem(tag, item);
  };

  render() {
    const {className, strings, label, data, inputClassName, error, disabled} = this.props;
    let { current } = this.props
    const {displayList} = this.state;
    
    if (current === 'MR') current = 'Monsieur'
    if (current === 'MME') current ='Madame'

    return (
      <div className={`clean-drop-down ${className}`}>
        <p className={'label'}>{label}</p>
        <div className={'selector-container'}>
          <div
            className={`box ${inputClassName ? inputClassName : ''} ${disabled ? 'disabled' : ''}`}
            onClick={() => !disabled ? this.handleClickBox() : undefined}
            ref={this.boxRef}>
            <p className={'current'}>{current ? current : disabled ? null : strings('select')}</p>
            <div className={'arrow ' + (displayList ? 'up' : '')}/>
          </div>
          <span className={`error ${inputClassName && error ? inputClassName : ''}`}>{error}</span>
          <div
            className={`list ${displayList && 'display'}`}>
            {!disabled && data.map((d, i) =>
              <div key={i} className={`row`}
                   onClick={this.handleClickItem(d)}>
                <p>{d}</p>
              </div>,
            )}
          </div>
        </div>
      </div>
    );
  }
}

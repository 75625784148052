import moment from 'moment';
import { images } from '../assets';

export const Constant = {
  URL: {
    default: process.env.REACT_APP_BASE_API_URL,
    fetchRegion: '/inscription/regions',
    fetchInstitutionDetails: '/inscription/details',
    createRecord: '/inscription/record',
    getSchoolById: '/inscription/record/{id}',
    saveSchool: '/inscription/record/{id}',
    finishSubscription: '/inscription/record/{id}',
    connectSchool: '/inscription/record/login',
    inscription: '/inscription',
    record: '/record',
    login: '/login',
    mentor: '/mentor',
    rne: '/rne',
    epaWebsite: 'https://www.entreprendre-pour-apprendre.fr/',
    register: '/register',
    epaRegionWebsite: 'http://www.entreprendre-pour-apprendre.fr/fr/qui-sommes-nous-/epaenfrance.html',
  },

  METHODS: {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    PATCH: 'PATCH',
    DELETE: 'delete',
  },

  PATH: {
    DEFAULT: '/',
    HOME: '/home',
    NEW_SCHOOL: '/inscription/school/new',
    CONNECT_SCHOOL: '/inscription/school/connect',
    RNE: '/inscription/school/rne',
    INSTITUTION: '/inscription/school/details',
    PROJECT: '/inscription/school/project',
    RESUME: '/inscription/school/resume',
    MENTOR: '/mentor',
    MENTOR_INSCRIPTION: '/mentor/inscription',
    SCHOOL: '/school',
    SCHOOL_INSCRIPTION: '/school/inscription',
    LEGAL_MENTIONS: '/mentions-legales',
  },

  REGEX: {
    EMAIL: /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    PHONE: /((0[1-9]|00([\s\\.\\-]*[0-9]){3}|\+[0-9]{2}[\s\\.\\-]*[1-9])([\s\\.\\-]*[0-9]){8})/,
    RNE: /^[\d]{7}[a-zA-Z]$/,
  },

  PROJECT_KEY: 'project-',

  SCHOOL_STATUS: {
    PENDING: 'PENDING',
    DRAFT: 'DRAFT'
  },

  CONFLICT_ERROR_CODES: {
    RNE_CONFLICT: 'RneAlreadyExists',
    OTHER_CONFLICT: 'AlreadyExists',
  }
};

export const defaultSupervisor = {
  civility: {
    value: null,
    onError: false,
  },
  firstName: {
    value: null,
    onError: false,
  },
  lastName: {
    value: null,
    onError: false,
  },
  email: {
    value: null,
    onError: false,
  },
  phone: {
    value: null,
    onError: false,
  },
};

const getSeasons = () => {
  let now = moment();
  let limitDate = moment(`01/09/${now.get("year")}`, 'DD/MM/YYYY');
  if (now.isBefore(limitDate)) {
    return [`${now.get('year') - 1}/${now.get("year")}`, `${now.get('year')}/${now.get("year") + 1}`];
  } else {
    return [`${now.get('year')}/${now.get("year") + 1}`, `${now.get('year') + 1}/${now.get("year") + 2}`]
  }
}
export const defaultProject = {
  value: {
    type: {
      value: 'M13',
      onError: false,
    },
    season: {
      value: getSeasons()[0],
      onError: false
    },
    supervisors: [],
  },
  onError: false,
};

export const projectTypes = [
  {
    value: 'M13',
    image: images.logoMiniM,
  },
  {
    value: 'L',
    image: images.logoMiniL,
  },
  {
    value: 'XL',
    image: images.logoMiniXL,
  },
];


import {createReducer} from 'reduxsauce';

export const types = {
  REGIONS_REQUESTED: 'REGIONS_REQUESTED',
  REGIONS_SUCCEEDED: 'REGIONS_SUCCEEDED',
};

const initialState = {
  regions: [],
};

const regionsSucceeded = (state = initialState, action) => {
  const {regions} = action;

  return {...state, regions: regions};
};


export default createReducer(initialState, {
  [types.REGIONS_SUCCEEDED]: regionsSucceeded,
});

import React from 'react';
import { Enum } from '../../../../utils/index';
import { DropDown, Input } from '../../../components/index';
import './userForm.scss';

export const UserForm = ({strings, userInfo, handleValueChanged, disabled = false}) => {
  //Need to convert drop down value which is a string to the enum key value
  const handleCivilityDropDown = (tag, value) => {
    handleValueChanged(tag, value === strings(Enum.CIVILITY.MR.label) ? Enum.CIVILITY.MR.key : Enum.CIVILITY.MRS.key)
  };

  return (
    <div className={'user-form'}>
      <div className={'wrapper'}>
        <DropDown
          tag={'civility'}
          className={'civility'}
          label={strings('civility')}
          data={Object.values(Enum.CIVILITY).map(g => strings(g.label))}
          inputClassName={`${userInfo.civility.onError ? 'invalid' : 'valid'}`}
          error={userInfo.civility.onError ? strings(userInfo.civility.errorMsg) : null}
          current={userInfo.civility.value ? userInfo.civility.value === Enum.CIVILITY.MR.key ? strings(Enum.CIVILITY.MR.label) : strings(Enum.CIVILITY.MRS.label) : null}
          handleClickItem={handleCivilityDropDown}
          disabled={disabled}
        />
        <Input
          tag={'lastName'}
          className={'lastName'}
          label={strings('lastName')}
          type={'text'}
          inputClassName={`${userInfo.lastName.onError ? 'invalid' : 'valid'}`}
          error={userInfo.lastName.onError ? strings(userInfo.lastName.errorMsg) : null}
          defaultValue={userInfo.lastName.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
          tag={'firstName'}
          className={'firstName'}
          label={strings('firstName')}
          type={'text'}
          inputClassName={`${userInfo.firstName.onError ? 'invalid' : 'valid'}`}
          error={userInfo.firstName.onError ? strings(userInfo.firstName.errorMsg) : null}
          defaultValue={userInfo.firstName.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'email'}
          className={'email'}
          label={strings('email')}
          type={'text'}
          inputClassName={`${userInfo.email.onError ? 'invalid' : 'valid'}`}
          error={userInfo.email.onError ? strings(userInfo.email.errorMsg) : null}
          defaultValue={userInfo.email.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
          tag={'phone'}
          className={'phone'}
          label={strings('phone')}
          type={'text'}
          inputClassName={`${userInfo.phone.onError ? 'invalid' : 'valid'}`}
          error={userInfo.phone.onError ? strings(userInfo.phone.errorMsg) : null}
          defaultValue={userInfo.phone.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

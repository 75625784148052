import React from 'react';

export const Item = ({text, route, location, navigateTo, itemClick = () => {}}) => {
  const clickItem = () => {
    navigateTo(route);
    itemClick()
  };

  return (<li
    className={`item ${route === location ? 'is-current' : ''}`}
    onClick={() => route !== location && clickItem()}>
    {text}
  </li>);
};

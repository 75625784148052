import moment from "moment";

export default {
  /* Common Form */
  address: "Adresse",
  postalCode: "Code postal",
  city: "Ville",
  region: "Région",
  academy: "Académie",
  country: "Pays",
  civility: "Civilité",
  lastName: "Nom",
  firstName: "Prénom",
  email: "Email",
  phone: "Numéro de téléphone",
  managerPhone: "Numéro de téléphone du chef de projet",
  principalCivility: "Civilité",
  principalLastName: "Nom",
  principalFirstName: "Prénom",
  principalEmail: "Email",
  principalPhone: "Numéro de téléphone du chef de projet",
  connect_me: "Me connecter",

  /* Generic Button */
  edit: "Modifier",
  delete: "Supprimer",
  validate: "Valider",

  /* Enum */
  mr: "Monsieur",
  mrs: "Madame",
  int: "M",
  long: "L",

  /* Common */
  select: "Selectionner",
  input_require: "Ce champs est obligatoire",
  input_rne_require: "Le code UAI doit contenir 7 chiffres et 1 lettre",
  input_invalid: "Ce champs est invalide",
  wrong_email: "Format d'email invalide",
  wrong_phone: "Format de numéro invalide",
  inconsistent_supervisor: "Encadrant incohérent",
  duplicate_email: "Email déjà renseigné",
  cancel: "Annuler",

  /* Inscription */
  inscription_title: "Comment s'inscrire ?",

  /* Mentor */
  mentor_home_title:
    "Devenir Mentor d'une Mini-Entreprise<sup>®</sup> avec Entreprendre Pour Apprendre",
  mentor_home_desc:
    "Dans la démarche d'Entreprendre pour Apprendre, le mentor, issu du monde professionnel, est un partenaire de choix de la Mini-Entreprise<sup>®</sup>. A titre individuel, ou dans le cadre de votre engagement professionnel, accompagnez un groupe de jeunes de 13 à 25 ans dans leur découverte des réalités de la vie professionnelle et aidez-les à développer leurs projets, en leur rendant visite en classe cinq à dix fois dans l'année pour suivre l'avancée de leur projet, les conseiller, les challenger et les aider à aller plus loin.",
  mentor_home_subdesc:
    "Les mentors sont accompagnés par les équipes d'Entreprendre pour Apprendre, avec des rencontres régulières ainsi qu'une plate-forme et des outils pédagogiques.",
  mentor_home_inscription_desc:
    "En saisissant sur cette plateforme vos coordonnées et en indiquant le périmètre géographique et les jours et horaires où vous pouvez vous rendre disponibles, ainsi que le nom et l'adresse de votre entreprise.",
  mentor_home_info_bloc1_title:
    "A quoi je m'engage en m'inscrivant sur cette plateforme ?",
  mentor_home_info_bloc1_desc:
    "L'inscription sur cette plateforme indique que vous signalez être prêt à mentorer un projet de Mini-Entreprise à proximité de votre domicile ou votre lieu de travail. Vous serez ensuite recontacté par un représentant d'Entreprendre Pour Apprendre qui vous proposera un ou plusieurs projets à proximité et vous demandera un engagement formel. Être mentor suppose d'être disponible pour se rendre cinq à dix fois dans l'année dans l'établissement, à la rencontre des jeunes.",
  mentor_home_info_bloc2_title: "A quoi s'engage Entreprendre Pour Apprendre ?",
  mentor_home_info_bloc2_desc1:
    "Une fois formellement engagé comme mentor d'une Mini-Entreprise, Entreprendre Pour Apprendre vous fournira\n\n" +
    "Un accès premium à notre plateforme pédagogique, incluant un tableau de bord pour suivre le projet à distance, l'accès illimité à 100% de nos ressources pédagogiques ainsi qu'un système de classement personnel des ressources",
  mentor_home_info_bloc2_desc2:
    "Un facilitateur d'Entreprendre Pour Apprendre, disponible pour vous accompagner dans la démarche d'élaboration et de mise en œuvre du projet et dans votre relation avec les encadrants pédagogiques de l'établissement\n\n" +
    "Un parcours de formation\n\n" +
    "Une assurance en responsabilité civile générale qui vous couvrira pour l'ensemble des activités des Mini-Entreprises<sup>®</sup>",
  mentor_home_info_bloc3_title: "Combien ça coûte ?",
  mentor_home_info_bloc3_desc:
    "L'engagement de mentor est bien sûr gratuit, mais ne peut faire l'objet d'aucune rétribution sous quelque forme que ce soit. Entreprendre Pour Apprendre ne prend pas en charge les frais de déplacement",
  register: "Inscription",
  mentor_inscription: "Inscription Mentor",
  mentor_inscription_user_info: "Un peu plus sur vous*",
  mentor_inscription_user_perimeter: "Votre périmètre d'action*",
  mentor_inscription_user_company: "La structure que vous representez*",
  mentor_inscription_user_ceo: "Dirigeant",
  description: "Description",
  companyName: "Raison sociale",
  ceoFirstName: "Prénom",
  ceoLastName: "Nom",
  ceoEmail: "Email",
  ceoPhone: "Téléphone",
  error_mentor_creation:
    "Une erreur est survenue lors de la création de votre compte Mentor. Veuillez réessayer plus tard.",
  success_mentor_creation:
    "Votre compte Mentor a bien été créé. Vous devriez recevoir un mail",
  mentor_already_exists:
    "Un compte utilisateur est déjà associé à cet e-mail.",
  account_already_exists:
      "Cet email est déjà enregistré chez nous, veuillez vous connecter avec votre email et mot de passe ou réinitialiser votre mot de passe &nbsp;&nbsp;&nbsp; <a href=\"/\" style=\"color:#ffffff;text-decoration:none; font-size:16px; border:1px solid #ffffff; border-radius:5px; padding:5px;\">Se connecter</a>",

  /* Schools */
  school_home_title:
    "Monter une Mini-Entreprise<sup>®</sup> dans mon établissement avec Entreprendre Pour Apprendre",
  school_home_desc:
    "Participer au parcours Mini-Entreprise<sup>®</sup>, c'est l'opportunité de vivre une aventure pédagogique enrichissante pour tous !",
  school_home_subdesc:
    "La découverte du monde de l'entreprise est une chance pour les jeunes.\n\n" +
    "L'aventure entrepreneuriale permet d'aller à la découverte de ses capacités, de ses aspirations tout en acquérant des savoirs de manière active. C'est un complément de ce qui leur est transmis au long de l'année scolaire.\n\n" +
    "Les équipes d'Entreprendre pour Apprendre vous accompagnent tout au long de l'année, en facilitant la mise en place du parcours choisi et en vous fournissant des outils pédagogiques adaptés au parcours, à l'âge et à l'état d'avancement du projet. Elle vous met en relation avec un mentor issu du monde de l'entreprise qui aidera les jeunes à mieux appréhender les réalités professionnelles et à concrétiser leur projet de Mini-Entreprise<sup>®</sup>.",
  school_home_inscription_desc:
    "Sur cette plateforme, vous pouvez inscrire votre établissement à trois parcours de la Mini-Entreprise<sup>®</sup>",
  school_home_inscription_doc_title:
    "Pour vous inscrire, vous devez avoir à proximité",
  school_home_inscription_doc_list1:
    "Le code UAI de votre établissement (ou ses coordonnées précises si votre établissement ne possède pas de code UAI)",
  school_home_inscription_doc_list2:
    "Les coordonnées du chef d’établissement (Prénom, Nom, email et téléphone)",
  school_home_inscription_doc_list3:
    "Les coordonnées du ou des encadrants pédagogiques prévus pour encadrer le projet (Prénom, Nom, email et téléphone)",
  school_home_inscription_doc_conclusion:
    "Vous pouvez à tout moment sauvegarder votre inscription en cours de saisie pour la reprendre ultérieurement",
  school_home_inscription_contact:
    "Pour les autres parcours, prenez contact directement avec l’antenne d’",
  school_home_inscription_contact_link:
    "Entreprendre Pour Apprendre dans votre région",
  school_home_inscription_age_young: "9-12 ans",
  school_home_inscription_age_old: "13-25 ans",
  school_home_info_bloc1_title:
    "A quoi l’établissement s’engage en s’inscrivant sur cette plateforme ?",
  school_home_info_bloc1_desc:
    "L’inscription sur cette plateforme ne constitue pas un engagement de votre part. Seule la signature par le chef d’établissement, d’une convention avec Entreprendre Pour Apprendre constitue un engagement contractuel. Cette convention, éditée à partir des éléments que vous allez saisir lors de l’inscription en ligne, sera directement adressée au chef d’établissement.",
  school_home_info_bloc2_title: "A quoi s’engage Entreprendre Pour Apprendre ?",
  school_home_info_bloc2_desc1:
    "Lors de la signature d’une convention entre votre établissement et une des seize associations membres du réseau Entreprendre Pour Apprendre, cette dernière s’engage :\n\n" +
    "A vous ouvrir un accès premium à notre plateforme pédagogique, incluant un tableau de bord pour piloter votre projet, l’accès illimité à 100% de nos ressources pédagogiques ainsi qu’un système de classement personnel des ressources\n\n" +
    "A vous accompagner dans la recherche d’un mentor, professionnel issu du monde de l’entreprise qui accompagnera à vos côté les jeunes dans leur projet",
  c:
    "A désigner au sein de son équipe un facilitateur, disponible pour vous accompagner dans la démarche d’élaboration et de mise en œuvre du projet \n\n" +
    "A vous proposer un parcours de formation\n\n" +
    "A vous couvrir dans le cadre de notre assurance en responsabilité civile générale pour l’ensemble des activités des Mini-Entreprises®\n\n" +
    "A couvrir le risque financier des Mini-Entreprises L<sup>®</sup> à hauteur du capital de démarrage collecté ",
  school_home_info_bloc2_desc2: `A désigner au sein de son équipe un facilitateur, disponible pour vous accompagner dans la démarche d’élaboration et de mise en œuvre du projet\n\n A vous proposer un parcours de formation \n\n A vous couvrir dans le cadre de notre assurance en responsabilité civile générale pour l’ensemble des activités des Mini-Entreprises<sup>®</sup> \n\n A couvrir le risque financier des Mini-Entreprises L<sup>®</sup> à hauteur du capital de démarrage collecté.`,
  school_home_info_bloc3_title: "Combien ça coûte ?",
  school_home_info_bloc3_desc1:
    "Le coût de mise en place d’une Mini-Entreprise<sup>®</sup> varie selon les lieux, en fonction des prises en charges par des fonds publics ou privés ayant pu être mobilisés localement.\n\n",
  contact_epa:
    "Contactez l’antenne d’Entreprendre Pour Apprendre dans votre région",
  school_home_info_bloc3_desc2:
    "pour connaître le tarif qui vous sera appliqué.",

  /* School Connection */
  code: "Code",
  password: "Password",
  access: "Accéder",
  pursue_inscription: "Reprise d'inscription",
  error_school_inscription_already_exist:
    "Un établissement est déjà associé à cette adresse email",
  error_school_inscription_creation:
    "Une erreur est survenue lors de la création de votre dossier. Veuillez réessayer plus tard.",
  error_school_login:
    "Une erreur est survenue lors de la tentative de connexion",
  error_school_retrieval:
    "Une erreur est survenue lors de récupération de votre dossier.",

  /* School Form */
  school_title: "L'établissement",
  school_inscription: "Inscription Établissement",
  school_inscription_step_rne: "1/4",
  school_inscription_step_school: "2/4",
  school_inscription_step_projects: "3/4",
  school_inscription_step_validation: "4/4",
  save: "Enregistrer",
  give_up: "Abandonner",

  /* Rne */
  rne: "code UAI de votre établissement",
  rne_placeholder: "ex: 0753824Z",
  rne_search: "Rechercher mon établissement",
  rne_skip: "Je n'ai pas de code UAI",
  next_step: "Passer à l'étape suivante",
  error_rne_retrieval:
    "Une erreur est survenue lors de recherche de votre code UAI",
  error_rne_not_found:
    "Votre code UAI ne correspond à aucun établissement connu",
  error_school_update:
    "Une erreur est survenue lors de l'enregistrement. Veuillez réessayer plus tard",
  error_school_validation:
    "Une erreur est survenue lors de la validation de votre inscription. Veuillez réessayer plus tard",
  error_email_exists:
    "Certains emails sont déjà associés à d'autres utilisateurs",
  rne_exists: "Ce code UAI est déjà associé à un établissement. Vous y serez automatiquement associé. Veuillez mettre à jour les informations si nécessaire.",
  success_school_creation: "Votre demande d’inscription a bien été envoyée et sera prochainement validée par un administrateur.",
  success_school_update: "Les données de l'établissement ont bien été modifiées.",

  /* School Info */
  new_search: "Lancer une nouvelle recherche",
  school_name: "Nom de l'établissement",
  schoolUai: "Code UAI",
  secretariatPhone: "Numéro de téléphone du secrétariat",
  principal: "Chef d'établissement",
  school_section_title: "Établissement",
  rne_replace_warning:
    "Attention, en procédant à cette action vous perdrez vos données déjà saisies." +
    "Souhaitez-vous poursuivre ?",
  give_up_replace_warning:
    "Attention, en abandonnant maintenant, vous perdrez vos informations non enregistrées." +
    "Souhaitez-vous poursuivre ?",

  /* Projects */
  projects_title: "Les projets",
  project_title: "Projet #",
  supervisor_title: "Encadrant #",
  supervisor: "Encadrant",
  add_project: "Ajouter un autre projet",
  add_supervisor: "Ajouter un encadrant",
  empty_project_error: "Veuillez ajouter au moins un encadrant au projet",
  conflict_message: "Cet email est déjà associé à un autre utilisateur",

  /* Summary */
  summary_title: "Votre inscription",
  summary_projects_title: "Projets",
  finish_inscription: "Terminer l'inscription",

  /* Menu */
  information: "Présentation",
  mentor: "Devenir mentor",
  school: "Inscrire un établissement",

  /* HOME PAGE */
  welcome_text:
    "Bienvenue sur la plateforme pédagogique d’Entreprendre Pour Apprendre",
  /* Row 1 */
  row1_h1:
    "Cette plateforme est l’outil principal pour mettre en œuvre les parcours",
  row1_h1_span: "Mini-Entreprises<sup>®</sup>",
  row1_paragraph: "auprès des jeunes de 13 à 25 ans",
  row1_button_text: "En savoir plus sur Entreprendre Pour Apprendre",
  /* Row 2 */
  row2_h1:
    "Vous êtes enseignant, formateur, conseiller en insertion, animateur, responsable d’un établissement scolaire, d’insertion ou socio-culturel",
  row2_paragraph:
    "Vous découvrez Entreprendre Pour Apprendre et voulez en savoir plus sur notre offre pédagogique",
  row2_paragraph2:
    "Enregistrez-vous pour bénéficier d’un accès gratuit et illimité à une partie de nos ressources pédagogiques.",
  row2_button_text: "Découvrir sans engagement",
  /* Row 3 */
  row3_h1:
    "Vous voulez mettre en place dans votre établissement une Mini-Entreprise M<sup>®</sup> ou une Mini-Entreprise L<sup>®</sup> auprès de jeunes de 13 à 25 ans",
  row3_paragraph:
    "En savoir plus sur les parcours et accéder au formulaire d’inscription",
  row3_button_text: "S'inscrire",
  /* Row 4 */
  row4_h1:
    "Vous êtes cadre en entreprise ou chef d’entreprise, en activité ou jeune retraité",
  row4_paragraph:
    "Vous découvrez Entreprendre Pour Apprendre et voulez en savoir plus sur notre offre pédagogique",
  row4_button_text: "Découvrir sans engagement",
  /* Row 5 */
  row5_h1:
    "Vous connaissez Entreprendre Pour Apprendre et vous envisagez d’être mentor d’une Mini-Entreprise M<sup>®</sup> ou une Mini-Entreprise L<sup>®</sup> auprès de jeunes de 13 à 25 ans",
  row5_paragraph:
    "En savoir plus sur les rôles du mentor et accéder au formulaire d’inscription",
  row5_button_text: "S'engager",
  /* Row 6 */
  row6_h1:
    "Vous avez entre 13 et 25 ans, êtes parent d’un jeune de cet âge, ou êtes curieux sur l’action d’Entreprendre Pour Apprendre",
  row6_paragraph:
    "Enregistrez-vous pour bénéficier d’un accès gratuit et illimité à une partie de nos ressources pédagogiques",
  row6_button_text: "Découvrir sans engagement",

  /* FOOTER */
  copyright: `© ${moment().year()} Entreprendre pour Apprendre`,
  access_to: "Accédez au site",
  epa_com: "epa.com",

  /* LEGAL MENTIONS */
  /* legal mentions */
  legal_mentions: "Mentions Légales",
  private_policy: "Politique de confidentialité",
  cgu: "CGU",
  intellectual_property: "Propriété intellectuelle",
  legal_mentions_contact:
    "La plateforme pédagogique EPA est développée par :\n\n" +
    "Entreprendre Pour Apprendre France\n" +
    "La Filature – 32 rue du Faubourg Poissonnière\n" +
    "75010 Paris\n" +
    "\n" +
    "Numéro SIREN : 449 242 049\n\n" +
    "Contact : contact@epa-france.fr\n\n" +
    "EPA France est une association loi 1901 reconnue d’intérêt général et titulaire d’un agrément du Ministère de l’Education et de la Jeunesse.",
  private_policy_complete:
    "Politique de confidentialité et de traitement des données personnelles",
  private_policy_text:
    "Les informations recueillies par Entreprendre Pour Apprendre France (EPA France) à partir de cette plateforme pédagogique font l’objet d’un traitement informatique destiné au réseau EPA. Il s’agit des coordonnées des encadrants, des mentors et des établissements dans lesquels se mettent en place des programmes pédagogiques EPA.\n\n" +
    "Cette collecte de données a pour finalité de procéder aux inscriptions des établissements dans les parcours Mini-Entreprise M®, Mini-Entreprise L® et Mini-Entreprise XL®, mais également de pouvoir réaliser des statistiques sur notre action.\n\n" +
    "Ces données nominatives des encadrants et des mentors seront conservées par EPA France au maximum 2 ans après le dernier contact.\n\n" +
      "En plus des données mentionnées précédemment, veuillez noter que des logs de maintenance peuvent être collectés automatiquement lors de l'utilisation de cette plateforme pédagogique dans le but de garantir le bon fonctionnement et la sécurité de la plateforme. Ils peuvent également être utilisés à des fins d'analyse et de résolution de problèmes techniques. Les logs de maintenance contenant des données personnelles seront conservés pendant une durée limitée à 6 mois et seront accessibles uniquement aux personnes autorisées nécessitant ces informations dans le cadre de leurs fonctions. \n\n" +
    "Afin de protéger la confidentialité de vos données personnelles, EPA France s’engage à ne pas transmettre ni partager vos données avec d’autres entités, entreprises ou organismes extérieurs au réseau EPA.\n\n" +
    "Conformément à la loi Informatique et libertés du 6 janvier 1978 modifiée et aux dispositions du Règlement Général sur la Protection des Données Personnelles du 27 avril 2016, vous disposez d’un droit d’accès, de rectification, de portabilité et d’effacement de vos données personnelles.\n\n" +
    "Vous pouvez vous opposer au traitement de vos données et disposez du droit de retirer votre consentement à tout moment.\n\n" +
    "Pour exercer vos droits, vous pouvez vous adresser à : ",
  private_policy_contact: "contact@epa-france.fr",
  cgu_complete:
    "Conditions générales d'utilisation de la plateforme pedagogique EPA",
  cgu_presentation: "Présentation de la plateforme pedagogique EPA",
  cgu_presentation_text:
    "La plateforme pédagogique EPA a pour objectif de permettre aux encadrants, jeunes et mentors participant à un programme pédagogique EPA de suivre leur Mini-Entreprise® tout au long de l’année en mode gestion de projet.\n\n" +
    "Elle se présente sous la forme d’un espace dédié à chaque projet de Mini-Entreprise® et propose plusieurs types d’outils pédagogiques (documents, vidéos …) permettant aux jeunes et à leurs encadrant et mentor de mener à bien leur projet.\n\n" +
    "L’accès à l’espace dédié au projet se fait via un login et un mot de passe.\n\n" +
    "Toutes les ressources présentes sur la plateforme pédagogique sont consultables sur ordinateur, tablette et smartphone, en multi-accès.\n\n",
  cgu_inscription: "Inscription",
  cgu_inscription_text:
    "Toute personne a accès à un contenu freemium de la plateforme pédagogique EPA en laissant ses nom, prénom et contact mail.\n\n" +
    "Pour accéder au contenu Premium, et donc à l’ensemble des ressources pédagogiques de la plateforme, il est nécessaire de faire une demande d’inscription en indiquant les coordonnées de l’établissement qui va accueillir le programme EPA.\n\n" +
    "Un facilitateur de l’association régionale EPA compétente prendra contact avec vous pour finaliser l’inscription et vous transmettre la convention qui sera signée entre l’établissement et EPA pour la mise en place du programme EPA.\n\n",
  cgu_uses: "Utilisation",
  cgu_uses_text:
    "L’accès premium à la plateforme se fait grâce à un login et un mot de passe qui sont communiqués une fois que la convention entre l’établissement et EPA est signée.\n\n" +
    "L’utilisateur a alors accès à toutes les ressources pédagogiques présentes sur la plateforme et à son espace projet qui lui est dédié. Il peut alors consulter les documents et vidéos en ligne et les télécharger.\n\n" +
    "Nous attirons votre attention sur le fait que le téléchargement des documents enlève le caractère interactif des documents.\n\n" +
    "L’utilisateur s’engage à :\n\n" +
    "-\tne pas communiquer ses identifiants à des personnes extérieures au projet EPA,\n" +
    "-\tutiliser les ressources pédagogiques dans le strict cadre du projet EPA,\n" +
    "-\tne pas utiliser les ressources proposées à des fins commerciales.\n\n" +
    "EPA France se réserve le droit de modifier ou de corriger, à tout moment et ce sans préavis, le contenu de sa plateforme et des documents qui sont proposés, et ne peut être tenue pour responsable des conséquences éventuelles de ces modifications. \n\n",
  cgu_technical: "Problème technique",
  cgu_technical_text:
    "En cas de problème technique dans l’utilisation de la plateforme pédagogique EPA, ou en cas de perte de vos identifiants, merci de contacter EPA France : <b>plateforme@epa-france.fr</b>",
  intellectual_property_complete: "Propiété intellectuelle et copyright",
  intellectual_property_licence:
    "Les outils et supports sont sous licence Créative Commons <b>BY NC ND</b>",
  intellectual_property_mentions:
    "Les mentions liées à Entreprendre Pour Apprendre font intégralement partie des supports",
  intellectual_property_commercial:
    "Toute utilisation à des fins commerciales est passible de poursuites judiciaires",
  intellectual_property_shared:
    "Les supports ne peuvent être intégrés partiellement ou en totalité dans un autre support. Ils ne peuvent être partagés qu’en intégralité (fond et forme)",
  intellectual_property_text:
    "La structure générale, les textes, images, logo, vidéos, sons, savoir-faire, animations, documents téléchargeables, et plus généralement toutes les informations et contenus figurant sur la plateforme pédagogique EPA, sont la propriété d’EPA France ou font l’objet d’un droit d’utilisation ou d’exploitation.\n\n" +
    "Toute représentation, modification, reproduction, dénaturation, totale ou partielle, de tout ou partie de la plateforme ou de son contenu, par quelque procédé que ce soit, et sur quelque support que ce soit constituerait une contrefaçon sanctionnée par les articles L. 335-2 et suivants du Code de la Propriété Intellectuelle.",
  season: "Année scolaire"
};

export const icons = {
  MENU: "menu",
  CLOSE: "close",
  EDIT: "edit",
  ADD: "add",
  DELETE: "delete",
  VALIDATE: "done",
  SAVE: "save",
  ARROW_BACK: "arrow_back",
  ARROW_NEXT: "arrow_forward",
  PERSON: 'person',
};

import React from 'react';
import { icons } from '../../../../../assets/icons/materialIcons';
import { ButtonColors, Button } from '../../../../components';
import { ProjectItem } from './ProjectItem';
import ShortId from 'shortid';
import { usePageViews } from '../../../../../utils';

export const Projects = ({strings, className, projects, supervisors, editedSupervisor, handleDeleteProject, handleAddProject, handleDeleteSupervisor, handleAddSupervisor, handleValueChanged, handleProjectSupervisorChanged, switchToEditMode, handleNextStep, conflictingEmails}) => {
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
  return (
    <div className={`${className ? className : ''}`}>
      <p className={'title'}>{strings('projects_title')}</p>
      <hr/>
      <div className={'list-wrapper'}>
      {projects.map((project, index) =>
        <ProjectItem
          key={ShortId.generate()}
          strings={strings}
          project={project}
          supervisors={index > 0 ? supervisors : null}
          index={index}
          handleValueChanged={(tag, value) => handleValueChanged(index, tag, value)}
          editedSupervisor={editedSupervisor}
          switchToEditMode={switchToEditMode}
          handleDeleteProject={handleDeleteProject}
          handleProjectSupervisorChanged={handleProjectSupervisorChanged}
          handleDeleteSupervisor={handleDeleteSupervisor}
          handleAddSupervisor={handleAddSupervisor}
          conflictingEmails={conflictingEmails}/>,
      )}
      <Button
        className={'add-project'}
        label={strings('add_project')}
        color={ButtonColors.orange}
        icon={icons.ADD}
        action={handleAddProject}
        enabled={editedSupervisor.project === null && editedSupervisor.supervisor === null}/>
        <hr/>
    </div>
      <Button
        color={ButtonColors.green}
        label={strings('next_step')}
        className={'next'}
        action={handleNextStep}
      />
    </div>
  );
};

import React, { Fragment, PureComponent } from 'react';
import { images } from '../../../../assets';
import { Constant, usePageViews } from '../../../../utils';
import { ButtonColors, Button } from '../../../components';
import { CreationPopup } from './creation/CreationPopup';
import './home.scss';
import { LoginPopup } from './login/LoginPopup';

export class Home extends PureComponent {

  state = {
    loginDisplayed: false,
    creationDisplayed: false,
  };

  handleLogin = (code, password) => {
    this.props.login(code, password);
  };

  handleStartInscription = (firstName, lastName, email) => {
    this.props.createInscription(firstName, lastName, email);
  };

  handleShowLogin = () => {
    this.setState({creationDisplayed: false, loginDisplayed: true});
  };

  handleShowInscription = () => {
    this.setState({creationDisplayed: true, loginDisplayed: false});
  };

  handleHideLogin = () => {
    this.setState({loginDisplayed: false});
  };

  handleHideInscription = () => {
    this.setState({creationDisplayed: false});
  };

  render() {
    const {strings} = this.props;
    const {creationDisplayed, loginDisplayed} = this.state;
  let location = window.location.pathname + window.location.search;
  usePageViews(location);
    return (
      <Fragment>
        <LoginPopup
          strings={strings}
          action={this.handleLogin}
          displayed={loginDisplayed}
          cancelAction={this.handleHideLogin}/>
        <CreationPopup
          strings={strings}
          action={this.handleStartInscription}
          displayed={creationDisplayed}
          cancelAction={this.handleHideInscription}/>
        <div id={'homeSchool'}>
          <img
            className={'background'}
            src={images.background}
            alt={'background'}
          />
          <div className={'container'}>
            <div className={'header'}>
              <h1 className={'title'}>{strings('school_home_title')}</h1>
              <div className={'text'}>
                <div className={'desc'}>
                  <p className={'intro'}>{strings('school_home_desc')}</p>
                  <p className={'desc'}>{strings('school_home_subdesc')}</p>
                </div>
                <img
                  src={images.idea}
                  alt={'idea'}
                />
              </div>
            </div>
            <div className={'inscription'}>
              <div className={'info'}>
                <p className={'label'}>{strings('inscription_title')}</p>
                <p className={'desc'}>{strings('school_home_inscription_desc')}</p>
                <p className={'sub-label'}>{strings('school_home_inscription_doc_title')}</p>
                <ul className={'doc-list'}>
                  <li className={'desc'}>{strings('school_home_inscription_doc_list1')}</li>
                  <li className={'desc'}>{strings('school_home_inscription_doc_list2')}</li>
                  <li className={'desc'}>{strings('school_home_inscription_doc_list3')}</li>
                </ul>
                <p
                  className={'desc conclusion'}>{strings('school_home_inscription_doc_conclusion')}</p>
              </div>
              <div className={'side-info'}>
                <div className={'mini-icons-wrapper'}>
                  <div className={'mini-icons-container'}>
                    <img
                      className={'mini-icon'}
                      src={images.logoMiniM}
                      alt={'Icon Mini M'}
                    />
                    <p>{strings('school_home_inscription_age_old')}</p>
                  </div>
                  <div className={'mini-icons-container'}>
                    <img
                      className={'mini-icon'}
                      src={images.logoMiniL}
                      alt={'Icon Mini L'}
                    />
                  </div>
                  <div className={'mini-icons-container'}>
                    <img
                      className={'mini-icon'}
                      src={images.logoMiniXL}
                      alt={'Icon Mini L'}
                    />
                  </div>
                </div>
                <div className={'action-wrapper'}>
                  <Button
                    color={ButtonColors.blue}
                    label={strings('register')}
                    className={'register'}
                    action={this.handleShowInscription}
                  />
                  <Button
                    color={ButtonColors.blue}
                    label={strings('pursue_inscription')}
                    className={'pursue-inscription'}
                    action={this.handleShowLogin}
                  />
                </div>
              </div>

            </div>
            <div className={'contact'}>
              <div className={'text'}>
                <p className={'desc'}>
                  {strings('school_home_inscription_contact')}
                  <a
                    href={Constant.URL.epaRegionWebsite}
                    target="_blank"
                    className={'link'}
                    rel="noopener noreferrer"
                  >
                    {strings('school_home_inscription_contact_link')}
                  </a>
                </p>
              </div>
              <div className={'mini-icons-wrapper'}>
                <img
                  className={'mini-icon'}
                  src={images.logoMiniS}
                  alt={'Icon Mini S'}
                />
                <div className={'mini-icons-container'}>
                  <img
                    className={'mini-icon'}
                    src={images.logoMiniM}
                    alt={'Icon Mini S'}
                  />
                  <p>{strings('school_home_inscription_age_young')}</p>
                </div>
              </div>
            </div>
            <div className={'information'}>
              <div className={'single-block'}>
                <p className={'label'}>{strings('school_home_info_bloc1_title')}</p>
                <p className={'desc'}>{strings('school_home_info_bloc1_desc')}</p>
              </div>
              <div className={'double-block'}>
                <p className={'label'}>{strings('school_home_info_bloc2_title')}</p>
                <div className={'wrapper'}>
                  <p className={'desc'}>{strings('school_home_info_bloc2_desc1')}</p>
                  <p className={'desc'}>{strings('school_home_info_bloc2_desc2')}</p>
                </div>
              </div>
              <div className={'single-block'}>
                <p className={'label'}>{strings('school_home_info_bloc3_title')}</p>
                <p className={'desc'}>{strings('school_home_info_bloc3_desc1')}</p>
                <p className={'desc'}>
                    <a
                      href={Constant.URL.epaRegionWebsite}
                      target="_blank"
                      className={'link'}
                      rel="noopener noreferrer"
                    >
                      {strings('contact_epa')}
                    </a>
                    {strings('school_home_info_bloc3_desc2')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

import React from 'react';
import { icons } from '../../../../../assets';
import { usePageViews } from '../../../../../utils';
import { ButtonColors, Button } from '../../../../components';
import './navigation.scss';

export const Navigation = ({strings, handleSave, handleGiveUp, allowPrevStep, allowNextStep, handleChangeStep, setRef}) => {
  const handlePrevStep = () => {
    if(allowPrevStep) {
      handleChangeStep(-1)
    }
  };
  const handleNextStep = () => {
    if(allowNextStep) {
      handleChangeStep(+1)
    }
  };
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
    return (
        <div id={'sideNavigation'} ref={setRef}>
            <div className={'actions'}>
                <Button
                    color={ButtonColors.grey}
                    label={strings('save')}
                    className={'save'}
                    icon={icons.SAVE}
                    action={handleSave}
                />
                <Button
                    color={ButtonColors.grey}
                    label={strings('give_up')}
                    className={'give-up'}
                    icon={icons.CLOSE}
                    action={handleGiveUp}
                />
            </div>
            <div className={'step-navigation'}>
                <i className={`material-icons ${allowPrevStep ? '' : 'disabled'}`} onClick={handlePrevStep}>{icons.ARROW_BACK}</i>
                <div className={'vl'}/>
                <i className={`material-icons ${allowNextStep ? '' : 'disabled'}`} onClick={handleNextStep}>{icons.ARROW_NEXT}</i>
            </div>
        </div>
    );
};

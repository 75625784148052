import React from "react";
import {icons} from "../../../assets";
import "./side-bar.scss";

export const SideBar = ({back, menu, onBack}) => {

  const scrollTo = (id) => {
    const element = document.querySelector(`.${id}`);
    const menuHeight = document.getElementById('fixed-wrapper').getBoundingClientRect().height;

    window.scrollTo({
      top: element.offsetTop - menuHeight - 25,
      left: 0,
      behavior: 'smooth'
    })
  };

  return (
    <div id={'sideBar'}>
      {back && <div className="back-link" onClick={onBack}>
        <i className="material-icons">{icons.ARROW_BACK}</i>
        <p className={'link'}>{back}</p>
      </div>
      }
      {menu && <ul className={'menu'}>
        {Object.entries(menu).map(([key, value], index) =>
              <li
              key={key}
              id={value.id}
              onClick={() => scrollTo(value.id, index === 0)}
            >{value.label}</li>
        )}
      </ul>
      }
    </div>
  )
};

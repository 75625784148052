import {Constant} from '../utils';
import {service, recordService} from "./ws";

const routes = {
  createRecord: Constant.URL.default + Constant.URL.createRecord,
  connectSchool: Constant.URL.default + Constant.URL.connectSchool,
  getSchoolById: (id) => Constant.URL.default + Constant.URL.getSchoolById.replace("{id}", id),
  saveSchool: (id) => Constant.URL.default + Constant.URL.saveSchool.replace("{id}", id),
  finishSubscription: (id) => Constant.URL.default + Constant.URL.finishSubscription.replace("{id}", id),
};

export default {
  createRecord: (auth) => service(Constant.METHODS.PUT, routes.createRecord, auth, {}),
  connectSchool: (auth) => service(Constant.METHODS.POST, routes.connectSchool, auth, {}),
  getSchoolById: (id) => recordService(Constant.METHODS.GET, routes.getSchoolById(id), {}, {}),
  saveSchool: (id, school) => recordService(Constant.METHODS.PUT, routes.saveSchool(id), school, {}),
  finishSubscription: (id, status) => recordService(Constant.METHODS.PATCH, routes.saveSchool(id), status, {}),
};

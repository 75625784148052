import { Constant } from './Constant';

export const Enum = {
  CIVILITY: {
    MR: {
      key: 'MR',
      label: 'mr'
    },
    MRS: {
      key: 'MME',
      label: 'mrs'
    }
  }
};

export const navigationMenu = [
  {
    label: 'information',
    route: Constant.PATH.HOME,
  },
  {
    label: 'school',
    route: Constant.PATH.SCHOOL,
  },
  {
    label: 'mentor',
    route: Constant.PATH.MENTOR,
  },
];

export const footerLink = {
  LEGAL_MENTIONS: {
    label: "legal_mentions",
    link: Constant.PATH.LEGAL_MENTIONS
  },
};

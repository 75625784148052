import React from 'react';
import './form.scss';
import { Input } from '../../../components/inputs';
import { usePageViews } from '../../../../utils';

export const UserCompany = ({ strings, className, userCompany, handleValueChanged }) => {
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
  return (
    <div className={className}>
      <p className={'label'}>{strings('mentor_inscription_user_company')}</p>
      <div className={'wrapper'}>
        <Input
          tag={'companyName'}
          className={'company-name'}
          label={strings('companyName')}
          type={'text'}
          inputClassName={`${userCompany.companyName.onError ? 'invalid' : 'valid'}`}
          error={userCompany.companyName.onError ? strings(userCompany.companyName.errorMsg) : null}
          defaultValue={userCompany.companyName.value}
          handleValueChanged={handleValueChanged}
        />
        <Input
          tag={'address'}
          className={'address'}
          label={strings('address')}
          type={'text'}
          inputClassName={`${userCompany.address.onError ? 'invalid' : 'valid'}`}
          error={userCompany.address.onError ? strings(userCompany.address.errorMsg) : null}
          defaultValue={userCompany.address.value}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'postalCode'}
          className={'postal-code'}
          label={strings('postalCode')}
          type={'text'}
          inputClassName={`${userCompany.postalCode.onError ? 'invalid' : 'valid'}`}
          error={userCompany.postalCode.onError ? strings(userCompany.postalCode.errorMsg) : null}
          defaultValue={userCompany.postalCode.value}
          handleValueChanged={handleValueChanged}
        />
        <Input
          tag={'city'}
          className={'city'}
          label={strings('city')}
          type={'text'}
          inputClassName={`${userCompany.city.onError ? 'invalid' : 'valid'}`}
          error={userCompany.city.onError ? strings(userCompany.city.errorMsg) : null}
          defaultValue={userCompany.city.value}
          handleValueChanged={handleValueChanged}
        />
        <Input
          tag={'country'}
          className={'country'}
          label={strings('country')}
          type={'text'}
          inputClassName={`${userCompany.country.onError ? 'invalid' : 'valid'}`}
          error={userCompany.country.onError ? strings(userCompany.country.errorMsg) : null}
          defaultValue={userCompany.country.value}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <p className={'label'}>{strings('mentor_inscription_user_ceo')}</p>
      <div className={'wrapper'}>
        <Input
          tag={'ceoLastName'}
          className={'ceo-lastName'}
          label={strings('ceoLastName')}
          type={'text'}
          inputClassName={`${userCompany.ceoLastName.onError ? 'invalid' : 'valid'}`}
          error={userCompany.ceoLastName.onError ? strings(userCompany.ceoLastName.errorMsg) : null}
          defaultValue={userCompany.ceoLastName.value}
          handleValueChanged={handleValueChanged}
        />
        <Input
          tag={'ceoFirstName'}
          className={'ceo-firstName'}
          label={strings('ceoFirstName')}
          type={'text'}
          inputClassName={`${userCompany.ceoFirstName.onError ? 'invalid' : 'valid'}`}
          error={userCompany.ceoFirstName.onError ? strings(userCompany.ceoFirstName.errorMsg) : null}
          defaultValue={userCompany.ceoFirstName.value}
          handleValueChanged={handleValueChanged}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'ceoPhone'}
          className={'ceo-phone'}
          label={strings('phone')}
          type={'text'}
          inputClassName={`${userCompany.ceoPhone.onError ? 'invalid' : 'valid'}`}
          error={userCompany.ceoPhone.onError ? strings(userCompany.ceoPhone.errorMsg) : null}
          defaultValue={userCompany.ceoPhone.value}
          handleValueChanged={handleValueChanged}
        />
        <Input
          tag={'ceoEmail'}
          className={'ceo-email'}
          label={strings('email')}
          type={'text'}
          inputClassName={`${userCompany.ceoEmail.onError ? 'invalid' : 'valid'}`}
          error={userCompany.ceoEmail.onError ? strings(userCompany.ceoEmail.errorMsg) : null}
          defaultValue={userCompany.ceoEmail.value}
          handleValueChanged={handleValueChanged}
        />
      </div>
    </div>
  );
};

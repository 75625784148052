import React from 'react';
import { icons } from '../../../../../../assets';
import { Project } from './Project';

export const ProjectsSummary = ({strings, className, projects, handleEditAction}) => {
  return (
    <div className={`section ${className ? className : ''}`}>
      <div className={'title-wrapper'}>
        <p className={'title'}>{strings('summary_projects_title')}</p>
        {handleEditAction &&
        <div className={'edit-wrapper'}
             onClick={handleEditAction}>
          <p>{strings('edit')}</p>
          <i className={'material-icons edit'}>{icons.EDIT}</i>
        </div>
        }
      </div>
      {projects.map((project, index) =>
        <Project
          key={index}
          className={'project'}
          strings={strings}
          project={project}
          index={index}/>,
      )}
    </div>
  );
};

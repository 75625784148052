import React from 'react';
import { images, icons } from '../../../assets';
import { Constant, navigationMenu } from '../../../utils';
import { default as Item } from './item';
import { default as Menu } from './menu';
import './navigations.scss';

export const Navigation = (
  {
    strings,
    navigateTo
  }) => (
  <div id={'navigation'}>
    <div id={'fixed-wrapper'}>
      <Menu/>
      <div className={'main-logo'} onClick={() => navigateTo(Constant.PATH.HOME)}>
        <img src={images.logoText} alt={'navigation-logo'}/>
      </div>
      <ul className={'items'}>
        {navigationMenu.map((item, key) =>
          <Item
            key={key}
            text={strings(item.label)}
            route={item.route}
          />,
        )}
      </ul>
      <div 
        className={'identification'}
        onClick={() => window.location.href = process.env.REACT_APP_DASHBOARD_URL}
      >
          <span>{strings('connect_me')}</span>
        <i className={'material-icons'}>{icons.PERSON}</i>
      </div>
    </div>
  </div>
);

import React, { PureComponent } from 'react';
import { icons } from '../../../../assets';
import { navigationMenu } from '../../../../utils';
import { default as Item } from '../item';

export class Menu extends PureComponent {

  state = {
    displayed: false,
  };

  toggleMenu = () => {
    const {displayed} = this.state;
    this.setState({displayed: !displayed});
  };

  render() {
    const {strings} = this.props;
    const {displayed} = this.state;
    
    return (
      <div id={'menu'}>
        <i onClick={this.toggleMenu} className={'material-icons menu'}>{icons.MENU}</i>
        <div className={`wrapper ${displayed ? 'displayed' : ''}`}>
          <ul className={'items'}>
            {navigationMenu.map((item, key) =>
              <Item
                key={key}
                text={strings(item.label)}
                route={item.route}
                itemClick={() => this.toggleMenu()}
              />,
            )}
          </ul>
        </div>
      </div>
    );
  }
}

import React from 'react';
import { icons } from '../../../../../assets/icons/materialIcons';
import { projectTypes, usePageViews } from '../../../../../utils';
import { ButtonColors, Button, DropDown } from '../../../../components';
import { RadioButtons } from '../../../../components/radioButtons/RadioButton';
import { DeletableUser } from '../common/userDetail/DeletableUser';
import ShortId from 'shortid';
import moment from 'moment';

export const ProjectItem = ({strings, className, project, supervisors, editedSupervisor, index, handleDeleteProject, handleDeleteSupervisor, handleAddSupervisor, handleValueChanged, handleProjectSupervisorChanged, switchToEditMode, conflictingEmails}) => {
  const projectUserEmails = project.value.supervisors.map(sup => sup.email.value);
  const supervisorsForDropDown = supervisors ? supervisors.filter(sup => !projectUserEmails.includes(sup.email.value)) : null;
    let location = window.location.pathname + window.location.search;
    usePageViews(location);

  const getSeasons = () => {
      let now = moment();
      let limitDate = moment(`01/09/${now.get("year")}`, 'DD/MM/YYYY');
      if (now.isBefore(limitDate)) {
        return [`${now.get('year') - 1}/${now.get("year")}`, `${now.get('year')}/${now.get("year") + 1}`];
      } else {
        return [`${now.get('year')}/${now.get("year") + 1}`, `${now.get('year') + 1}/${now.get("year") + 2}`]
      }
    }
  const seasonList = [
      getSeasons()[0],
      getSeasons()[1]
    ];
  return (
    <div className={`section ${className ? className : ''}`}>
      <div className={'info'}>
        <p className={'title'}>{`${strings('project_title')}${index + 1}`}</p>
        <div className={'type'}>
          <RadioButtons
            strings={strings}
            buttons={projectTypes}
            defaultValue={project.value.type.value}
            tag={'type'}
            handleValueChanged={handleValueChanged}/>
        </div>
        {index > 0 &&
        <div className={'delete-wrapper'} onClick={() => handleDeleteProject(index)}>
          <p>{strings('delete')}</p>
          <i className={'material-icons delete'}>{icons.DELETE}</i>
        </div>
        }
      </div>
      <div className={'info'}>
        <div className={'season'}>
          <DropDown
              tag={'season'}
              label={strings('season')}
              data={seasonList}
              current={project.value.season.value ? project.value.season.value : seasonList[0]}
              handleClickItem={handleValueChanged}
          />
        </div>
      </div>
      {supervisorsForDropDown && supervisorsForDropDown.length > 0 &&
      <DropDown
        tag={'supervisor'}
        className={'supervisor'}
        label={strings('supervisor')}
        data={supervisorsForDropDown.map(supervisor => `${supervisor.firstName.value} ${supervisor.lastName.value}`)}
        handleClickItem={(tag, value) => handleAddSupervisor(index, supervisorsForDropDown.findIndex(supervisor => `${supervisor.firstName.value} ${supervisor.lastName.value}` === value))}
      />
      }
      <div className={'supervisors'}>
        {project.value.supervisors.map((supervisor, idx) =>
          <DeletableUser
            key={ShortId.generate()}
            strings={strings}
            className={'supervisor-info'}
            title={`${strings('supervisor_title')}${idx + 1}`}
            userInfo={supervisor}
            isEditing={editedSupervisor.project === index && editedSupervisor.supervisor === idx}
            editable={editedSupervisor.project === null && editedSupervisor.supervisor === null}
            switchToEditMode={() => switchToEditMode(index, idx)}
            handleDeleteSupervisor={() => handleDeleteSupervisor(index, idx)}
            handleValueChanged={(supervisor) => handleProjectSupervisorChanged(index, idx, supervisor)}
            conflictError={conflictingEmails.includes(supervisor.email.value)}/>,
        )}
      </div>
      <div className={'bottom-wrapper'}>
        <Button
          className={'add-supervisor'}
          label={strings('add_supervisor')}
          color={ButtonColors.orange}
          icon={icons.ADD}
          action={() => handleAddSupervisor(index)}
          enabled={editedSupervisor.project === null && editedSupervisor.supervisor === null}/>
        {project.onError &&
        <div className={'error'}>
          <p>{strings('empty_project_error')}</p>
        </div>
        }
      </div>
      <hr/>
    </div>
  );
};

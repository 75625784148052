import React, {PureComponent} from 'react';
import {Constant, usePageViews} from '../../../../../utils';
import {Button, ButtonColors, Input} from '../../../../components';
import '../form.scss';

export class Rne extends PureComponent {
  state = {
    rne: null,
    rneInvalid: false,
    validationTriggered: false,
  };

  getValidityClass = isInvalid => {
    return this.state.validationTriggered ? isInvalid ? 'invalid' : 'valid' : '';
  };

  handleKeyPressed = (e) => {
    if ((e.keyCode ? e.keyCode : e.which) === 13) {
      e.preventDefault();
      const {rne} = this.state;
      this.handleValidation(rne);
    }
  };

  handleValidation = (rne) => {

    const {action} = this.props;
    const rneInvalid = !rne || rne.length === 0 || !Constant.REGEX.RNE.test(rne)
    this.setState({
      validationTriggered: true,
      rneInvalid,
    });

    if (!rneInvalid) {
      action(rne);
    }
  };

  handleValueChanged = (tag, value) => {
    this.resetValidator();
    this.setState({[tag]: value});
  };

  resetValidator = () => {
    if (this.state.validationTriggered) {
      this.setState({rneInvalid: false});
    }
  };

  render() {
    const {strings, className} = this.props;
    const {rne, rneInvalid} = this.state;
  let location = window.location.pathname + window.location.search;
  usePageViews(location);
    return (
      <div className={`${className ? className : ''}`}>
        <p className={'title'}>{strings('school_title')}</p>
        <hr/>
        <form className={'form-wrapper'} onKeyDown={this.handleKeyPressed}>
          <Input
            label={strings('rne')}
            placeholder={strings('rne_placeholder')}
            type={'text'}
            tag={'rne'}
            className={'rne'}
            inputClassName={`${this.getValidityClass(rneInvalid)}`}
            error={rneInvalid ? strings('input_rne_require') : null}
            handleValueChanged={this.handleValueChanged}
          />
          <Button
            color={ButtonColors.green}
            label={strings('rne_search')}
            className={'search'}
            action={() => this.handleValidation(rne)}
          />
        </form>
      </div>
    );
  }
}

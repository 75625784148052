import { getTranslate } from 'react-localize-redux/lib/index';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { CommonsActions, CommonsSelectors } from '../../../../redux/commons';
import { InscriptionsActions } from '../../../../redux/inscriptions';
import { Form } from './Form';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  regions: CommonsSelectors.getRegions(state),
});

const mapDispatchToProps = dispatch => ({
  fetchRegions: () => dispatch(CommonsActions.regionsRequested()),
  createMentor: (mentor) => dispatch(InscriptionsActions.createMentorRequested(mentor)),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

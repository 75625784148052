import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Home } from './Home';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

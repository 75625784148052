import {Constant} from '../utils';
import {service} from "./ws";

const routes = {
  getRegions: Constant.URL.default + Constant.URL.fetchRegion
};

export default {
  getRegions: () => service(Constant.METHODS.GET, routes.getRegions, {}, {}),
};

import {CONFLICT} from "http-status-codes";
import { put } from 'redux-saga/effects'
import {SnackActions} from "../../redux/snackBar";
import ErrorTypeEnum from "../../Enum/ErrorTypeEnum";
export default {
    *displayConflictError (error, substituteMessage) {
        if (error.response.status === CONFLICT && error.response.data.code === ErrorTypeEnum.ERROR_ALREADY_EXISTS) {
            yield  put(SnackActions.displayError("account_already_exists"));
        } else {
            yield put(SnackActions.displayError(substituteMessage));
        }
    }
}

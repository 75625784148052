import React from 'react';
import { icons } from '../../../../../assets';
import { usePageViews } from '../../../../../utils';
import { DropDown, Input } from '../../../../components';

export const SchoolInfo = ({strings, className, schoolInfo, regions, academies, handleValueChanged, handleEditAction, disabled = false}) => {
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
  return (
    <div className={`section ${className ? className : ''}`}>
      <div className={'title-wrapper'}>
        <p className={'title'}>{strings('school_section_title')}</p>
        {handleEditAction &&
        <div className={'edit-wrapper'}
             onClick={handleEditAction}>
          <p>{strings('edit')}</p>
          <i className={'material-icons edit'}>{icons.EDIT}</i>
        </div>
        }
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'name'}
          className={'name'}
          label={strings('school_name')}
          type={'text'}
          inputClassName={`${schoolInfo.name.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.name.onError ? strings(schoolInfo.name.errorMsg) : null}
          defaultValue={schoolInfo.name.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
            tag={'rne'}
            className={'rne'}
            label={strings('schoolUai')}
            type={'text'}
            inputClassName={`${schoolInfo.rne.onError ? 'invalid' : 'valid'}`}
            error={schoolInfo.rne.onError ? strings(schoolInfo.rne.errorMsg) : null}
            defaultValue={ schoolInfo.rne.value ? schoolInfo.rne.value.toUpperCase(): null}
            handleValueChanged={handleValueChanged}
            disabled={true}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'address'}
          className={'address'}
          label={strings('address')}
          type={'text'}
          inputClassName={`${schoolInfo.address.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.address.onError ? strings(schoolInfo.address.errorMsg) : null}
          defaultValue={schoolInfo.address.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
          tag={'postalCode'}
          className={'postal-code'}
          label={strings('postalCode')}
          type={'text'}
          inputClassName={`${schoolInfo.postalCode.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.postalCode.onError ? strings(schoolInfo.postalCode.errorMsg) : null}
          defaultValue={schoolInfo.postalCode.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
          tag={'city'}
          className={'city'}
          label={strings('city')}
          type={'text'}
          inputClassName={`${schoolInfo.city.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.city.onError ? strings(schoolInfo.city.errorMsg) : null}
          defaultValue={schoolInfo.city.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
      </div>
      <div className={'wrapper'}>
        <DropDown
          tag={`region`}
          className={'region'}
          label={strings('region')}
          data={regions}
          inputClassName={`${schoolInfo.region.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.region.onError ? strings(schoolInfo.region.errorMsg) : null}
          current={schoolInfo.region.value}
          handleClickItem={handleValueChanged}
          disabled={disabled}
        />
        <DropDown
          tag={'academy'}
          className={`academy`}
          label={strings('academy')}
          data={academies}
          inputClassName={`${schoolInfo.academy.onError ? 'invalid' : 'valid'} ${academies && academies.length < 1 ? 'not-allowed' : ''}`}
          error={schoolInfo.academy.onError ? strings(schoolInfo.academy.errorMsg) : null}
          current={schoolInfo.academy.value}
          handleClickItem={handleValueChanged}
          disabled={disabled}
        />
      </div>
      <div className={'wrapper'}>
        <Input
          tag={'country'}
          className={'country'}
          label={strings('country')}
          type={'text'}
          inputClassName={`${schoolInfo.country.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.country.onError ? strings(schoolInfo.country.errorMsg) : null}
          defaultValue={schoolInfo.country.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
        <Input
          tag={'secretariatPhone'}
          className={'phone'}
          label={strings('secretariatPhone')}
          type={'phone'}
          inputClassName={`${schoolInfo.secretariatPhone.onError ? 'invalid' : 'valid'}`}
          error={schoolInfo.secretariatPhone.onError ? strings(schoolInfo.secretariatPhone.errorMsg) : null}
          defaultValue={schoolInfo.secretariatPhone.value}
          handleValueChanged={handleValueChanged}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

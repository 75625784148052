import { resettableReducer } from 'reduxsauce';
import { CommonsReducers, CommonsSagas } from './commons';
import { InscriptionsReducers, InscriptionsSagas } from './inscriptions';
import { LoaderReducers } from './loader';
import { SnackReducers, SnackSagas } from './snackBar';

// Specify reset action type rule
const resettable = resettableReducer('RESET_REDUCERS');

const reducers = Object.assign({},
  {
    inscriptions: resettable(InscriptionsReducers),
    commons: resettable(CommonsReducers),
    loader: resettable(LoaderReducers),
    snackBar: SnackReducers,
  },
);

export const rootSaga = [
  ...SnackSagas,
  ...InscriptionsSagas,
  ...CommonsSagas,
];

export default reducers;

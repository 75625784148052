import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { footerLink } from '../../../utils';
import { Footer } from './Footer';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  footerLink: Object.entries(footerLink).map(([_, value]) => ({
    label: getTranslate(state.locale)(value.label),
    link: value.link,
  })),
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);

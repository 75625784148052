import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { CommonsActions, CommonsSelectors } from '../../../../redux/commons';
import { InscriptionsActions, InscriptionsSelectors } from '../../../../redux/inscriptions';
import { Form } from './Form';

const mapStateToProps = (state) => ({
  strings: getTranslate(state.locale),
  regions: CommonsSelectors.getRegions(state),
  academies: region => CommonsSelectors.getAcademies(region)(state),
  school: InscriptionsSelectors.getSchoolDetails(state),
  isSchoolFilled: state.inscriptions.record && state.inscriptions.record.school,
  updateInError: state.inscriptions.updateInError,
  conflictingEmails: state.inscriptions.conflictingEmails,
});

const mapDispatchToProps = dispatch => ({
  fetchRegions: () => dispatch(CommonsActions.regionsRequested()),
  getRne: (rne) => dispatch(InscriptionsActions.getRneRequested(rne)),
  updateSchool: (school) => dispatch(InscriptionsActions.updateSchoolRequested(school)),
  validateSchool: (school) => dispatch(InscriptionsActions.validateSchoolRequested(school)),
  resetSchool: () => dispatch(InscriptionsActions.resetSchoolInscription()),
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);

import React from 'react';
import './home.scss';
import { images } from '../../../assets';
import { Constant } from '../../../utils';
import { usePageViews } from '../../../utils';

export const Home = ({ strings, navigateTo }) => {
  let location = window.location.pathname + window.location.search;
  usePageViews(location);
  return (
    <div id={'home'}>
      <img
        className={'background'}
        src={images.background}
        alt={'background'}
      />
      <div className={'intro'}>{strings('welcome_text')}</div>
      <div className={'container'}>
        <div className={'row row-1'}>
          <div className={'top'}>
            <h1 className={'title'}>
              {strings('row1_h1')} <span> {strings('row1_h1_span')}</span>
            </h1>
            <p>{strings('row1_paragraph')}</p>
            <button
              onClick={() => window.open(Constant.URL.epaWebsite, '_blank')}
            >
              {strings('row1_button_text')}
            </button>
          </div>
          <div className={'bottom'}>
            <div className={'img-1'}>
              <img
                className={'logo-mini-m'}
                src={images.logoMiniM}
                alt={'logo-mini-m'}
              />
            </div>
            <div className={'img-2'}>
              <img
                className={'logo-mini-l'}
                src={images.logoMiniL}
                alt={'logo-mini-l'}
              />
            </div>
            <div className={'img-3'}>
              <img
                className={'innovation'}
                src={images.innovation}
                alt={'innovation'}
              />
            </div>
          </div>
        </div>
        <div className={'row row-2'}>
          <div className={'desc-1'}>
            <h1>{strings('row2_h1')}</h1>
            <p>{strings('row2_paragraph')}</p>
          </div>
          <div className={'pic'}>
            <img src={images.imgEnseignant} alt={'img-enseignant'} />
          </div>
          <div className={'desc-2'}>
            <p>{strings('row2_paragraph2')}</p>
            <button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AUTH_URL}${Constant.URL.register}`,
                  '_blank'
                )
              }
            >
              {strings('row2_button_text')}
            </button>
          </div>
        </div>
        <div className={'row'}>
          <div className={'desc'}>
            <h1>{strings('row3_h1')}</h1>
            <p>{strings('row3_paragraph')}</p>
            <button onClick={() => navigateTo(Constant.PATH.SCHOOL)}>
              {strings('row3_button_text')}
            </button>
          </div>
          <div className={'pic'}>
            <img src={images.idea} alt={'ideas'} />
          </div>
        </div>
        <div className={'row row-4'}>
          <div className={'desc'}>
            <h1>{strings('row4_h1')}</h1>
            <p>{strings('row4_paragraph')}</p>
            <button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AUTH_URL}${Constant.URL.register}`,
                  '_blank'
                )
              }
            >
              {strings('row4_button_text')}
            </button>
          </div>
          <div className={'pic'}>
            <img src={images.hands} alt={'ideas'} />
          </div>
        </div>
        <div className={'row'}>
          <div className={'desc'}>
            <h1>{strings('row5_h1')}</h1>
            <p>{strings('row5_paragraph')}</p>
            <button onClick={() => navigateTo(Constant.PATH.MENTOR)}>
              {strings('row5_button_text')}
            </button>
          </div>
          <div className={'pic'}>
            <img src={images.sunavatar} alt={'sun avatar'} />
          </div>
        </div>
        <div className={'row row-6'}>
          <div className={'desc'}>
            <h1>{strings('row6_h1')}</h1>
            <p>{strings('row6_paragraph')}</p>
            <button
              onClick={() =>
                window.open(
                  `${process.env.REACT_APP_AUTH_URL}${Constant.URL.register}`,
                  '_blank'
                )
              }
            >
              {strings('row6_button_text')}
            </button>
          </div>
          <div className={'pic'}>
            <img src={images.apprenants} alt={'ideas'} />
          </div>
        </div>
      </div>
    </div>
  );
};

import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import { Item } from './Item';

const mapStateToProps = state => ({
  location: state.router.location.pathname,
});

const mapDispatchToProps = dispatch => ({
  navigateTo: path => dispatch(push(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Item);

import React, { useEffect } from 'react';
import { images } from '../../../assets';
import './legal-mentions.scss';
import { SideBar } from '../../components/sidebar/SideBar';
import { usePageViews } from '../../../utils';

export const LegalMentions = ({ strings }) => {
  let location = window.location.pathname + window.location.search;
  usePageViews(location);
  const menu = {
    legalMentions: {
      id: 'legal-mentions',
      label: strings('legal_mentions'),
    },
    privatePolicy: {
      id: 'private-policy',
      label: strings('private_policy'),
    },
    cgu: {
      id: 'cgu',
      label: strings('cgu'),
    },
    intellectualProperty: {
      id: 'intellectual-property',
      label: strings('intellectual_property'),
    },
  };

  const makeBold = () => {
    const currentEntry = Object.values(menu).find(ref => {
      const menuTitle = document.querySelector(`#${ref.id}`);

      return menuTitle.style.fontWeight === 'bold';
    });

    const menuElement = Object.values(menu).find(ref => {
      const section = document.querySelector(`.${ref.id}`);
      const sectionTitle = section.querySelector('.title');
      const sectionTopPosition = section.getBoundingClientRect().top;
      const menuHeight = document
        .getElementById('fixed-wrapper')
        .getBoundingClientRect().height;

      return (
        sectionTopPosition + sectionTitle.getBoundingClientRect().height >
          menuHeight && sectionTopPosition < window.innerHeight
      );
    });

    if (menuElement && menuElement !== currentEntry) {
      Object.values(menu).forEach(ref => {
        const menuTitle = document.querySelector(`#${ref.id}`);
        menuTitle.style.fontWeight = 'normal';
      });

      const menuElementTitle = document.querySelector(`#${menuElement.id}`);
      menuElementTitle.style.fontWeight = 'bold';
    }
  };

  useEffect(() => {
    //Call make bold to set the default menu state
    makeBold();
    document.addEventListener('scroll', makeBold);
    return () => document.removeEventListener('scroll', makeBold);
  });

  return (
    <div id={'legalMentions'}>
      <img
        className={'background'}
        src={images.epaBackground}
        alt={'background'}
      />
      <div className={'container'}>
        <div className={'side-bar'}>
          <SideBar menu={menu} />
        </div>
        <div className={'content'}>
          <div className={'section legal-mentions'} id={'legal-mentions'}>
            <p className={'title'}>{strings('legal_mentions')}</p>
            <hr className={'separator'} />
            <p className={'text'}>{strings('legal_mentions_contact')}</p>
          </div>
          <div className={'section private-policy'} id={'private-policy'}>
            <p className={'title'}>{strings('private_policy_complete')}</p>
            <hr className={'separator'} />
            <p className={'text'}>
              {strings('private_policy_text')}
              <a className={'text'} href={'mailto:contact@epa-france.fr'}>
                {strings('private_policy_contact')}
              </a>
            </p>
          </div>
          <div className={'section cgu'} id={'cgu'}>
            <p className={'title'}>{strings('cgu_complete')}</p>
            <hr className={'separator'} />
            <p className={'subtitle'}>{strings('cgu_presentation')}</p>
            <p className={'text'}>{strings('cgu_presentation_text')}</p>
            <p className={'subtitle'}>{strings('cgu_inscription')}</p>
            <p className={'text'}>{strings('cgu_inscription_text')}</p>
            <p className={'subtitle'}>{strings('cgu_uses')}</p>
            <p className={'text'}>{strings('cgu_uses_text')}</p>
            <p className={'subtitle'}>{strings('cgu_technical')}</p>
            <p className={'text'}>{strings('cgu_technical_text')}</p>
          </div>
          <div
            className={'section intellectual-property'}
            id={'intellectual-property'}
          >
            <p className={'title'}>
              {strings('intellectual_property_complete')}
            </p>
            <hr className={'separator'} />
            <p className={'text'}>{strings('intellectual_property_licence')}</p>
            <div className={'copyright'}>
              <img className={'icon'} src={images.mentions} alt={'mentions'} />
              <p className={'text'}>
                {strings('intellectual_property_mentions')}
              </p>
            </div>
            <div className={'copyright'}>
              <img
                className={'icon'}
                src={images.commercial}
                alt={'commercial'}
              />
              <p className={'text'}>
                {strings('intellectual_property_commercial')}
              </p>
            </div>
            <div className={'copyright'}>
              <img className={'icon'} src={images.equal} alt={'shared'} />
              <p className={'text'}>
                {strings('intellectual_property_shared')}
              </p>
            </div>
            <p className={'text'}>{strings('intellectual_property_text')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, {PureComponent} from 'react';
import './footer.scss';
import { Constant } from '../../../utils';

export class Footer extends PureComponent {
  render() {
    const {strings, footerLink, navigateTo} = this.props;

    return (
      <div id={'footer'}>
        <div className={"content"}>
          <p id={"copyright"}>{strings("copyright")}</p>
          <div id={'link'}>
            {footerLink.map(item =>
              <p onClick={() => navigateTo(item.link)} key={item.label}>{item.label}</p>
            )}
          </div>
          <p id={'go-web-epa'} onClick={() => window.open(Constant.URL.epaWebsite, "_blank")}>{strings("access_to")}</p>
        </div>
      </div>
    );
  }
}

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { logger } from '../../../utils';
import './input.scss';

export class Input extends PureComponent {

  state = {
    value: this.props.defaultValue,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(this.props.defaultValue !== this.state.value && this.props.defaultValue !== prevProps.value) {
      this.setState({value: this.props.defaultValue});
    }
  }

  valueChanged = event => {
    const {value} = event.target;
    const {tag, handleValueChanged} = this.props;

    this.setState({value});

    handleValueChanged(tag, value);
  };

  render() {
    const {label, placeholder, type, className, inputClassName, error, maxLength, disabled} = this.props;
    const {value} = this.state;

    return (
      <div className={`input-wrapper ${className}`}>
        <span className={'label'}>{label}</span>
          <input
            className={`${inputClassName ? inputClassName : ''}`}
            type={type}
            ref={this.input}
            placeholder={placeholder}
            value={value ?? ''}
            maxLength={maxLength ? maxLength : ''}
            onChange={this.valueChanged}
            disabled={disabled}
          />
        <span className={`error ${inputClassName && error ? inputClassName : ''}`}>{error}</span>
      </div>
    );
  }
}

Input.propsType = {
  label: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  handleValueChanged: PropTypes.func,
};

Input.defaultProps = {
  type: 'text',
  handleValueChanged: () => logger.warn('handleValueChanged not implemented.'),
};



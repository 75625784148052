import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { LegalMentions } from './LegalMentions';

const mapStateToProps = state => ({
  strings: getTranslate(state.locale),
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(LegalMentions);

import React, { PureComponent } from 'react';
import { ButtonColors, Button } from '../../../../components';
import { SchoolInfo } from '../common/SchoolInfo';
import { UserInfo } from '../../../common';
import { WarningPopup } from '../popup/WarningPopup';
import { usePageViews } from '../../../../../utils';

export default class School extends PureComponent {

  state = {
    rneWarningRaised: false,
    rne: null,
  };

  handleRneSearch = (rne) => {
    this.setState({
      rneWarningRaised: true, rne
    });
  };

  handleCancel = () => {
    this.setState({rneWarningRaised: false});
  };

  handleConfirm = () => {
    const {handleRneSearch} = this.props;
    const {rne} = this.state;
    this.setState({rneWarningRaised: false});
    handleRneSearch(rne);
  };

  render() {
    const {strings, className, schoolInfo, principalInfo, regions, academies, handleValueChanged, handleNextStep} = this.props;

    if(schoolInfo.rne){
      this.setState({rne: schoolInfo.rne})
    }
    const {rneWarningRaised} = this.state;
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
    return (
      <div className={`${className ? className : ''}`}>
        <p className={'title'}>{strings('school_title')}</p>
        <SchoolInfo
          strings={strings}
          className={'school-info'}
          schoolInfo={schoolInfo}
          regions={regions}
          academies={academies}
          handleValueChanged={(tag, value) => handleValueChanged('schoolInfo', tag, value)}
        />
        <hr/>
        <UserInfo
          strings={strings}
          className={'principal-info'}
          title={strings('principal')}
          userInfo={principalInfo}
          handleValueChanged={(tag, value) => handleValueChanged('principalInfo', tag, value)}/>
        <hr/>
        <Button
          color={ButtonColors.green}
          label={strings('next_step')}
          className={'next'}
          action={handleNextStep}
        />
        {rneWarningRaised &&
        <WarningPopup
          strings={strings}
          label={strings('rne_replace_warning')}
          displayed={true}
          confirmAction={this.handleConfirm}
          cancelAction={this.handleCancel}/>
        }
      </div>
    );
  }
}

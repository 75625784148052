import React, { PureComponent } from 'react';
import { icons } from '../../../../../../assets/index';
import { validateSupervisorForm } from '../../FormValidator';
import './deletableUser.scss';
import { UserForm } from '../../../../common/user/UserForm';
import { usePageViews } from '../../../../../../utils';

export class DeletableUser extends PureComponent {

  state = {
    userInfo: {...this.props.userInfo},
  };

  handleValueChanged = (tag, value) => {
    const {userInfo} = this.state;

    if (userInfo[tag]) {
      userInfo[tag].value = (value !== '' ? value : null);
    }

    //Reset on error state
    Object.entries(userInfo).filter(([key]) => key !== 'id').map(([key, val]) => val.onError = false);

    this.setState({userInfo: Object.assign({}, userInfo)});
  };

  handleEditionAction = () => {
    const {isEditing, handleValueChanged, switchToEditMode} = this.props;
    const {userInfo} = this.state;

    if (isEditing) {
      const result = validateSupervisorForm(userInfo);

      this.setState({userInfo: Object.assign({}, result)});

      const fields = [];
      Object.entries(result).forEach(
        ([key, value]) => {
          fields.push({key: key, data: value});
        },
      );

      if (!fields.some(field => field.data.onError)) {
        handleValueChanged(result);
        switchToEditMode();
      }
    } else {
      switchToEditMode();
    }
  };

  render() {
    const {strings, className, title, isEditing, editable, conflictError, handleDeleteSupervisor} = this.props;
    const {userInfo} = this.state;
      let location = window.location.pathname + window.location.search;
      usePageViews(location);
    return (
      <div
        className={`user-info section ${className ? className : ''} ${isEditing ? '' : 'disabled'}`}>
        <div className={'header'}>
          <p className={'title'}>{title}</p>
        </div>
        <UserForm
          strings={strings}
          userInfo={userInfo}
          disabled={!isEditing}
          handleValueChanged={this.handleValueChanged}/>
        <div id={'add-edit'}>
          {conflictError &&
          <p>{strings('conflict_message')}</p>
          }
          <div className={`edit-wrapper ${isEditing || editable ? '' : 'disabled'}`}
               onClick={this.handleEditionAction}>
            <p>{strings(isEditing ? 'validate' : 'edit')}</p>
            <i className={'material-icons edit'}>{isEditing ? icons.VALIDATE : icons.EDIT}</i>
          </div>
          <div className={'delete-wrapper'} onClick={handleDeleteSupervisor}>
            <p>{strings('delete')}</p>
            <i className={'material-icons delete'}>{icons.DELETE}</i>
          </div>
        </div>
      </div>
    );
  }
}

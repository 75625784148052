import { createSelector } from 'reselect';

const rne = state => state.inscriptions.rne || null;
const school = state => state.inscriptions.record?.school
const prefilledSchool = state => state.inscriptions.record?.school || {
  name: null,
  address: null,
  postalCode: null,
  city: null,
  region: null,
  academy: null,
  country: null,
  rne: null,
  secretariatPhone: null,
  principalCivility: null,
  principalLastName: null,
  principalFirstName: null,
  principalEmail: null,
  principalPhone: null,
  projects: [],
};

const formatRNE = (rne) => {
  return {
    name: rne.name ? rne.name : null,
    address: rne.address ? rne.address : null,
    postalCode: rne.postalCode ? rne.postalCode.toString() : null,
    rne: rne.rne ? rne.rne : null,
    city: rne.city ? rne.city : null,
    region: rne.region ? rne.region : null,
    academy: rne.academy ? rne.academy : null,
    country: rne.country ? rne.country : null,
    secretariatPhone: rne.secretariatPhone ? rne.secretariatPhone : null,
    principalCivility: rne.principalCivility ? rne.principalCivility : null,
    principalLastName: rne.principalLastName ? rne.principalLastName : null,
    principalFirstName: rne.principalFirstName ? rne.principalFirstName : null,
    principalEmail: rne.principalEmail ? rne.principalEmail : null,
    principalPhone: rne.principalPhone ? rne.principalPhone : null,
  }
};

const getSchoolDetails = createSelector(
  school, prefilledSchool, rne, (school, prefilledSchool, rne) => {

    const {projects, ...schoolProps} = prefilledSchool;
    const props = rne && !school ? formatRNE(rne) : schoolProps;

    const schoolValues = {};
    Object.entries(props).map(([key, value]) => schoolValues[key] = {value, onError: false});

    const {principalCivility, principalLastName, principalFirstName, principalEmail, principalPhone, ...schoolInfo} = schoolValues;
    const principalInfo = {civility: principalCivility, firstName: principalFirstName, lastName: principalLastName, email: principalEmail, phone: principalPhone};

    const projectValues = [];
    projects.forEach(project => {
      const supervisors = project.supervisors.map(supervisor => {
        return {
          id: supervisor.id,
          civility: {
            value: supervisor.civility,
            onError: false,
          },
          firstName: {
            value: supervisor.firstName,
            onError: false,
          },
          lastName: {
            value: supervisor.lastName,
            onError: false,
          },
          email: {
            value: supervisor.email,
            onError: false,
          },
          phone: {
            value: supervisor.phone,
            onError: false,
          }
        }
      });
      projectValues.push({
        value: {
          id: project.id,
          type: {
            value: project.type,
            onError: false,
          },
          season: {
            value: project.season,
            onError: false
          },
          supervisors
        },
        onError: false,
      })
    });

    return {schoolInfo, principalInfo, projects: projectValues};
  }
);

export default {
  getSchoolDetails,
};

import { ConnectedRouter } from 'connected-react-router';
import React, { Fragment ,useEffect} from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Constant } from '../utils';
import { history } from './history';
import screens from './screens';
import store from './store';
import TagManager from 'react-gtm-module';

const RecordRoute = ({ component: Component, ...rest }) => {
  const { auth, record } = store.getState().inscriptions;
  return (
    <Route
      render={props =>
        auth && record ? (
          <Component {...props} {...rest} />
        ) :
            (
          <Redirect
            to={{ pathname: Constant.PATH.SCHOOL, state: { ...rest.location } }}
          />
        )
      }
    />
  );
};

const simpleRoute = () => {

  return Object.entries(screens).map(([key, value]) =>
    value.authRecord ? (
      <RecordRoute
        exact
        key={key}
        path={value.path}
        component={value.component}
      />
    ) : (
      <Route exact key={key} path={value.path} component={value.component} />
    )
  );
};

const ScrollToTop = () => {
  history.listen(() => window.scrollTo(0, 0));
  return null;
};

export default function Router() {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-5F36KT7' });
  },[])
  return (
    <ConnectedRouter history={history}>
      <Fragment>
        <ScrollToTop />
        <Switch>
          {simpleRoute()}
          <Redirect from={Constant.PATH.DEFAULT} to={Constant.PATH.HOME} />
        </Switch>
      </Fragment>
    </ConnectedRouter>
  );
}

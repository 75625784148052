import React from 'react';
import { projectTypes, usePageViews } from '../../../../../../utils';
import { RadioButtons } from '../../../../../components/radioButtons/RadioButton';
import { Supervisor } from './Supervisor';
import moment from 'moment';
import DropDown from '../../../../../components/dropDown';

export const Project = ({ strings, className, project, index }) => {
    let location = window.location.pathname + window.location.search;
    usePageViews(location);

    const getSeasons = () => {
      let now = moment();
      let limitDate = moment(`01/09/${now.get("year")}`, 'DD/MM/YYYY');
      if (now.isBefore(limitDate)) {
        return [`${now.get('year') - 1}/${now.get("year")}`, `${now.get('year')}/${now.get("year") + 1}`];
      } else {
        return [`${now.get('year')}/${now.get("year") + 1}`, `${now.get('year') + 1}/${now.get("year") + 2}`]
      }
    }
  const seasonList = [
      getSeasons()[0],
      getSeasons()[1]
    ];
  return (
    <div className={`section ${className ? className : ''}`}>
      <div className={'info'}>
        <p className={'title'}>{`${strings('project_title')}${index + 1}`}</p>
        <div className={'type'}>
          <RadioButtons
            strings={strings}
            buttons={projectTypes}
            defaultValue={project.value.type.value}
            disabled={true}/>
        </div>
        <div className={'season'}>
          <DropDown
            data={seasonList}
            strings={strings}
            label={strings('season')}
            current={project.value.season.value}
            disabled={true}
          />
        </div>
      </div>
      <div className={'supervisors'}>
        {project.value.supervisors.map((supervisor, idx) =>
          <Supervisor
            key={idx}
            strings={strings}
            supervisor={supervisor}/>,
        )}
      </div>
      <hr/>
    </div>
  );
};


import { actionCreator } from '../../utils/index';

// Types
export const types = {
  REGIONS_REQUESTED: 'REGIONS_REQUESTED',
  REGIONS_SUCCEEDED: 'REGIONS_SUCCEEDED',
};

// Actions
export default {
  regionsRequested: () => actionCreator(types.REGIONS_REQUESTED, {}),
  regionsSucceeded: regions => actionCreator(types.REGIONS_SUCCEEDED, {regions}),
};



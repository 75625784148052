import React from 'react';
import { usePageViews } from '../../../../utils';
import { DropDown, TextArea } from '../../../components';
import './form.scss';

export const UserPerimeter = ({ strings, className, userPerimeter, regions, handleValueChanged }) => {
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
  return (
    <div className={className}>
      <p className={'label'}>{strings('mentor_inscription_user_perimeter')}</p>
      <TextArea
        tag={'description'}
        className={'description'}
        label={strings('description')}
        type={'text'}
        inputClassName={`${userPerimeter.description.onError ? 'invalid' : 'valid'}`}
        error={userPerimeter.description.onError ? strings(userPerimeter.description.errorMsg) : null}
        defaultValue={userPerimeter.description.value}
        handleValueChanged={handleValueChanged}
      />
      <DropDown
        tag={'region'}
        className={'region'}
        label={strings('region')}
        data={regions}
        inputClassName={`${userPerimeter.region.onError ? 'invalid' : 'valid'}`}
        error={userPerimeter.region.onError ? strings(userPerimeter.region.errorMsg) : null}
        current={userPerimeter.region.value}
        handleClickItem={handleValueChanged}
      />
    </div>
  );
};

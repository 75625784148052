import { Constant } from '../utils';
import { recordService, service } from './ws';

const routes = {
  createMentor: Constant.URL.default + Constant.URL.inscription + Constant.URL.mentor,
  createSchoolInscription: Constant.URL.default + Constant.URL.inscription + Constant.URL.record,
  loginSchool: Constant.URL.default + Constant.URL.inscription + Constant.URL.record + Constant.URL.login,
  getSchoolInscription: (id) => Constant.URL.default + Constant.URL.inscription + Constant.URL.record + `/${id}`,
  getRne: (rne) => Constant.URL.default + Constant.URL.inscription + Constant.URL.rne + `/${rne}`,
  updateSchool: (id) => Constant.URL.default + Constant.URL.inscription + Constant.URL.record + `/${id}`,
};

export default {
  createMentor: (mentor) => service(Constant.METHODS.PUT, routes.createMentor, {...mentor}, {}),
  createSchoolInscription: (firstName, lastName, email) => service(Constant.METHODS.PUT, routes.createSchoolInscription, {firstName, lastName, email}, {}),
  loginSchool: (code, password) => service(Constant.METHODS.POST, routes.loginSchool, {code, password}, {}),
  getSchoolInscription: (id) => recordService(Constant.METHODS.GET, routes.getSchoolInscription(id), {}, {}),
  getRne: (rne) => service(Constant.METHODS.GET, routes.getRne(rne), {}, {}),
  updateSchool: (id, body) => recordService(Constant.METHODS.PUT, routes.updateSchool(id), {...body}, {}),
  validateSchool: (id, status) => recordService(Constant.METHODS.PATCH, routes.updateSchool(id), {status}, {}),
};

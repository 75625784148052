import React from 'react';
import { ButtonColors, Button, PopupWrapper } from '../../../../components';
import './warningPopup.scss';

export const WarningPopup = ({strings, label, displayed, confirmAction, cancelAction}) => {
  return (
    <PopupWrapper
      id={'warningPopup'}
      className={'warning-popup'}
      displayed={displayed}
      cancelAction={cancelAction}>
      <div className={'warning-container'}>
        <p>{label}</p>
        <div className={'popup-actions'}>
          <Button
            color={ButtonColors.green}
            label={strings('validate')}
            className={'validate'}
            action={confirmAction}
          />
          <Button
            color={ButtonColors.orange}
            label={strings('cancel')}
            className={'cancel'}
            action={cancelAction}
          />
        </div>
      </div>
    </PopupWrapper>
  );
};

import React from 'react'
import './textArea.scss'

export const TextArea = ({strings, className, tag, label, defaultValue, inputClassName, error, handleValueChanged}) => {
  const onValueChange = event => {
    const {value} = event.target;
    handleValueChanged(tag, value);
  };

  return (
    <div className={`text-area-wrapper ${className}`}>
      <span className={'label'}>{label}</span>
      <textarea
        className={`${inputClassName ? inputClassName : ''}`}
        rows={5}
        onChange={onValueChange}
        value={defaultValue}
      />
      <span className={`error ${inputClassName && error ? inputClassName : ''}`}>{error}</span>
    </div>
  )
};

import React, { PureComponent } from 'react';
import { Button, ButtonColors, FormHeader } from '../../../components';
import './form.scss';
import { validateMentorForm } from './FormValidator';
import { UserCompany } from './UserCompany';
import { UserInfo } from '../../common';
import { UserPerimeter } from './UserPerimeter';
import { usePageViews } from '../../../../utils';
import Cgu from "../../../components/cgu/Cgu";

export class Form extends PureComponent {
  state = {
    userInfo: {
      civility: {
        value: null,
        onError: false,
      },
      firstName: {
        value: null,
        onError: false,
      },
      lastName: {
        value: null,
        onError: false,
      },
      email: {
        value: null,
        onError: false,
      },
      phone: {
        value: null,
        onError: false,
      },
    },
    userPerimeter: {
      description: {
        value: null,
        onError: false,
      },
      region: {
        value: null,
        onError: false,
      },
    },
    userCompany: {
      companyName: {
        value: null,
        onError: false,
      },
      address: {
        value: null,
        onError: false,
      },
      postalCode: {
        value: null,
        onError: false,
      },
      city: {
        value: null,
        onError: false,
      },
      country: {
        value: null,
        onError: false,
      },
      ceoFirstName: {
        value: null,
        onError: false,
      },
      ceoLastName: {
        value: null,
        onError: false,
      },
      ceoEmail: {
        value: null,
        onError: false,
      },
      ceoPhone: {
        value: null,
        onError: false,
      },
    },
  };

  componentDidMount() {
    const { fetchRegions } = this.props;

    fetchRegions();
  }

  handleValueChanged = (tag, value) => {
    const { userInfo, userPerimeter, userCompany } = this.state;

    if (userInfo[tag]) {
      userInfo[tag].value = value;
    } else if (userPerimeter[tag]) {
      userPerimeter[tag].value = value;
    } else if (userCompany[tag]) {
      userCompany[tag].value = value;
    }
    //Reset on error state
    Object.values(userInfo).map(val => (val.onError = false));
    Object.values(userPerimeter).map(val => (val.onError = false));
    Object.values(userCompany).map(val => (val.onError = false));

    this.setState({
      userInfo: Object.assign({}, userInfo),
      userPerimeter: Object.assign({}, userPerimeter),
      userCompany: Object.assign({}, userCompany),
    });
  };

  handleValidation = () => {
    const { createMentor } = this.props;
    const { userInfo, userPerimeter, userCompany } = this.state;
    const result = validateMentorForm(userInfo, userPerimeter, userCompany);
    this.setState({
      userInfo: Object.assign({}, result.userInfo),
      userPerimeter: Object.assign({}, result.userPerimeter),
      userCompany: Object.assign({}, result.userCompany),
    });

    const fields = [];
    Object.keys(result).forEach(key => {
      const item = result[key];
      Object.keys(item).forEach(key => {
        fields.push({ key: key, data: item[key] });
      });
    });
    const inError = fields.some(field => field.data.onError);

    if (!inError) {
      const { civility, firstName, lastName, email, phone } = userInfo;
      const { description, region } = userPerimeter;
      const {
        companyName,
        address,
        postalCode,
        city,
        country,
        ceoLastName,
        ceoFirstName,
        ceoPhone,
        ceoEmail,
      } = userCompany;

      if (civility.value === 'Monsieur') civility.value = 'MR';
      if (civility.value === 'Madame') civility.value = 'MME';

      const mentor = {
        civility: civility.value.toUpperCase(),
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        phone: phone.value,
        description: description.value,
        region: region.value,
        companyName: companyName.value,
        companyAddress: address.value,
        companyPostalCode: postalCode.value,
        companyCity: city.value,
        companyCountry: country.value,
        ceoLastName: ceoLastName.value,
        ceoFirstName: ceoFirstName.value,
        ceoPhone: ceoPhone.value,
        ceoEmail: ceoEmail.value,
      };
      createMentor(mentor);
    }
  };

  render() {
    const { strings, regions } = this.props;
    const { userInfo, userPerimeter, userCompany } = this.state;
    let location = window.location.pathname + window.location.search;
    usePageViews(location);
    return (
      <div id={'formMentor'}>
        <FormHeader title={strings('mentor_inscription')}/>

        <div className={'container'}>
          <p className={'title'}>{strings('mentor_inscription_user_info')}</p>
          <hr/>
          <UserInfo
              strings={strings}
              className={'block'}
              userInfo={userInfo}
              handleValueChanged={this.handleValueChanged}
          />
          <hr/>
          <UserPerimeter
              strings={strings}
              className={'block'}
              userPerimeter={userPerimeter}
              regions={regions}
              handleValueChanged={this.handleValueChanged}
          />
          <hr/>
          <UserCompany
              strings={strings}
              className={'block'}
              userCompany={userCompany}
              handleValueChanged={this.handleValueChanged}
          />
          <hr/>
          <div className={'button-wrapper'}>
            <Cgu />
            <Button
                color={ButtonColors.green}
                label={strings('register')}
                className={'register'}
                action={this.handleValidation}
            />
          </div>
        </div>
      </div>
    );
  }
}

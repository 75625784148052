import React from 'react';
import { icons } from '../../../assets';
import './snackBar.scss';

export const SnackBar = ({isShown, message, level, forceHide}) => {
  return (
    <div
      id="infoView"
      className={isShown ? 'display-info' : 'hide-info'}
      style={{backgroundColor: level}}>
      <div>{message}</div>
      <i className="material-icons remove-info" onClick={forceHide}>{icons.CLOSE}</i>
    </div>
  );
};
